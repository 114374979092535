.checkout-step-shipping {
  #residence {
    margin-top: 20px;
  }

  .checkout-step-shipping-option {
      height: 100%;
      width: 100%;
      border: 1px solid $secondary-color;
      border-radius: 6px;
      font-size: 0.93em;
      box-shadow: 10px 11px 33px 0 rgba(0, 0, 0, 0.1);
      .checkout-step-shipping-selection-box-shipping-discount {
        font-size: 0.8em !important;
        margin-top: 9px;
      }
      &.inactive {
        border-color: $white;
        .checkout-step-shipping-selection-box-body{
          .checkout-step-shipping-selection-box-info{
            .checkout-step-shipping-selection-box-text{
              color: $black;
              font-weight: normal;
              text-align: left;
              margin-left: 10px;
            }
            .checkout-step-shipping-selection-box-shipping-discount {
              color: $black;
              font-weight: normal;
              text-align: left;
              margin-left: 10px;
            }
          }
        }

        .checkout-step-shipping-selection-box-footer {
          
          .checkout-step-shipping-selection-box-footer-check{
            display: none;
          }
          .checkout-step-shipping-selection-box-ripple {
            background: $white;
            border: 1px solid $secondary-color;
            width: 12px;
            height: 12px;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            border-radius: 100%;
            margin: 10px auto 0;
          }
        }
      }

      .checkout-step-shipping-selection-box-body {
        padding: 30px 15px 0;

        .checkout-step-shipping-selection-box-info-img{
          min-height: 30px;
          &.SMART_DELIVERY {
            @include svg-background('checkout/images/SMART_DELIVERY.svg', 70px);
          }
          &.VIDEO_IDENTIFICATION {
            @include svg-background('checkout/images/VIDEO_IDENTIFICATION.svg', 50px);
          }
        }

        .checkout-step-shipping-selection-box-info-textbox {
            margin-left: 10px;
            width: 82%;
        }

        .checkout-step-shipping-selection-box-info {
          .checkout-step-shipping-selection-box-text {
            color: $black;
            @include font-bold;
            font-size: 0.925em;
          }
        }
      }

      .checkout-step-shipping-selection-box-footer {
        position: absolute;
        width: 100%;
        height: 35px;
        bottom: -3px;
        .checkout-step-shipping-selection-box-footer-check{
          display: block;
          margin: 8px auto;
        }
      }
    height: 160px;

    md-radio-button {
      width: 100%;
      height: 98%;

      .md-ink-ripple {
        width: 100%;
        height: 100%;
        visibility: hidden;
        margin: 0;
        bottom: 0;
      }

      .md-label {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        margin: 0;

        span {
          width: 100%;
          height: 100%;
        }
      }
    }

    &.checkout-step-shipping-option-bonus {
      .checkout-step-shipping-selection-box-body {
        padding: 18px 15px 0;
        .checkout-step-shipping-selection-box-sim-discount {
          @include font-bold;
          margin-left: -16px;
          margin-top: -6px;
          margin-bottom: 9px;
          width: fit-content;
          color: $white;
          background: $alert--green;
          padding: 2px 13px 2px 10px;
          -webkit-clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 25%, 75% 0);
          clip-path: polygon(0 0, 0 100%, 100% 100%, 100% 92%, 92% 0);
        }
      }
    }
  }

  .widi-form {
    md-input-container {
      width: auto;
    }

    .widi-form-fieldset-content {
      margin: 0;
    }

    .checkout-step-shipping-options {
      margin: 30px 0;

      .md-ink-ripple {
        top: 0;
        margin-top: 10px;

        .md-ripple-container {
          margin-bottom: -30px;
        }
      }
    }
  }

  #videoriconoscimento {
    padding-left: 80px;
    .onlyOneDeliveryType{
      color: $black; // NEW $secundary-color
      font-size: 1.1em;
      @include font-bold;
    }
    #checkout-step-shipping-discount-tooltip {
      display: none;
    }
  }

  .checkout-step-shipping-separator {
    height: 2px;
    background-color: $grey; // NEW $grey
    width: 100%;
    margin-bottom: 30px;
  }

  #checkout-step-shipping-identification-question {
    @include font-bold;
    margin-bottom: 30px;
    font-size: 1.15em;
    text-transform: uppercase;
  }

  .checkout-step-shipping-identification-choice-title {
    @include font-bold;
  }

  .checkout-step-shipping-identification-choice-text {
    padding-top: 5px;
  }

  .liquid-child {
    overflow: visible;
  }

  .liquid-container {
    overflow: visible;
    display: inline;
  }

  .sameaddress-container {
    position: relative;
    #sameAddresses {
      md-checkbox {
        margin-bottom: 5px;

        /* .md-label {
          margin-left: 20px;
        } */
      }
    }
  }

  .sameaddressTooltip, .sameaddressTooltipOnlyDevice {
    .checkout-tooltip-text {
      top: 31px;
      left: 0;
    }
  }

  .checkout-tooltip-trigger {
    position: relative;
    top: 0;
  }

  #checkout-step-shipping-no-options {
    @include font-bold;
    margin: 0 0 20px;
  }

  #checkout-step-shipping-invalid-zipcode {
    background: $alert--red; // NEW $alert--red
    color: $white;
    @include font-bold;
    padding: 5px 10px;
    margin: 0 0 20px;
  }

  .checkout-step-shipping-alert {
    line-height: 1.5;
    margin: 10px 0 20px;
    padding: 10px;

    .checkout-step-shipping-alert-text {
      padding-top: 5px;
      font-size: 0.85em;
      @include font-bold;
      span {
        @include font-regular;
      }
    }

    .checkout-step-shipping-alert-icon {
      margin: 0 10px 0 3px;

      &.SMART_DELIVERY {
        @include svg-background('checkout/images/icona_pacco.svg', 50px);
      }

      &.VIDEO_IDENTIFICATION {
        @include svg-background('checkout/images/icona_sim.svg', 50px);
      }
    }
  }
}

.checkout-radio-choice-title {
  .widi-cart-price{
    display: inline;
  }
}

.otp-link {
  color: $secondary-color; // NEW $secundary-color
  cursor: pointer;
  text-decoration: underline;

  &.disabled {
    color: $secondary-color--light; // NEW $secundary-color--light
    cursor: not-allowed;
  }
}

$dialog-padding: $baseline-grid * 3 !default;

.md-dialog-is-showing {
  max-height: 100%;
}

.md-dialog-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: $z-index-dialog;
  overflow: hidden;
}

md-dialog {

  &.md-transition-in {
    opacity: 1;
    transition: $swift-ease-out;
    transform: translate(0,0) scale(1.0);
  }
  &.md-transition-out {
    opacity: 0;
    transition: $swift-ease-out;
    transform: translate(0,100%) scale(0.2);
  }

  opacity: 0;
  min-width: 240px;
  max-width: 80%;
  max-height: 80%;
  position: relative;
  overflow: auto; // stop content from leaking out of dialog parent and fix IE

  box-shadow: $whiteframe-shadow-13dp;

  display: flex;
  flex-direction: column;

  &> form {
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .md-dialog-content {
    padding: $dialog-padding;
  }

  md-dialog-content {
    order: 1;
    flex-direction: column;
    overflow: auto;
    -webkit-overflow-scrolling: touch;

    &:not([layout=row]) > *:first-child:not(.md-subheader) {
      margin-top: 0;
    }

    &:focus {
      outline: none;
    }

    .md-subheader {
      margin: 0;
    }

    .md-dialog-content-body {
      width:100%;
    }

    .md-prompt-input-container {
      width: 100%;
      box-sizing: border-box;
    }
  }

  .md-actions, md-dialog-actions {
    display: flex;
    order: 2;
    box-sizing: border-box;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;
    @include rtl(padding-right, $baseline-grid, $baseline-grid * 2);
    @include rtl(padding-left, $baseline-grid * 2, $baseline-grid);
    min-height: $baseline-grid * 6.5;
    overflow: hidden;

    .md-button {
      margin-bottom: $baseline-grid;
      @include rtl(margin-left, $baseline-grid, 0);
      @include rtl(margin-right, 0, $baseline-grid);
      margin-top: $baseline-grid;
    }
  }
  &.md-content-overflow {
    .md-actions, md-dialog-actions {
      border-top-width: 1px;
      border-top-style: solid;
    }
  }

}

@media screen and (-ms-high-contrast: active) {
  md-dialog {
    border: 1px solid #fff;
  }
}

@media (max-width: $layout-breakpoint-sm - 1) {
  md-dialog.md-dialog-fullscreen {
    min-height: 100%;
    min-width: 100%;
    border-radius: 0;
  }
}

md-grid-list {
  box-sizing: border-box;
  display: block;
  position: relative;

  md-grid-tile,
  md-grid-tile > figure,
  md-grid-tile-header,
  md-grid-tile-footer {
    box-sizing: border-box;
  }

  md-grid-tile {
    display: block;
    position: absolute;

    figure {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 0;
      margin: 0;
    }

    // Headers & footers
    md-grid-tile-header,
    md-grid-tile-footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 48px;
      color: #fff;
      background: rgba(0, 0, 0, 0.18);
      overflow: hidden;

      // Positioning
      position: absolute;
      left: 0;
      right: 0;

      h3,
      h4 {
        font-weight: 400;
        margin: 0 0 0 16px;
      }

      h3 {
        font-size: 14px;
      }

      h4 {
        font-size: 12px;
      }
    }

    md-grid-tile-header {
      top: 0;
    }

    md-grid-tile-footer {
      bottom: 0;
    }
  }

}

@media screen and (-ms-high-contrast: active) {
  md-grid-tile {
    border: 1px solid #fff;
  }
  md-grid-tile-footer {
    border-top: 1px solid #fff;
  }
}


header {
  box-shadow: 0 2px 15px 0px rgba(60, 60, 60, 0.2);
  background-color: $white;
  #checkout-header {
    display: flex;
    //background-color: transparent;
    //background: linear-gradient(to right, $checkout-color-orange 0%, $checkout-color-orange 60%, $checkout-color-dark-orange 100%); // NEW $white
    height: 84px;
    position: relative;
    max-width: 1024px;
    margin: 0 auto;
    margin-bottom: 0 !important;
    &-block {
      margin: 0 auto;
    }
    /* &::before {
      background: $checkout-color-white;
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      top: 0;
      right: 100%;
    }

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      display: block;
      top: 0;
      left: 100%;
      background: $checkout-color-dark-orange; // NEW $white
    } */

    #checkout-header-logo-bg {
      //background-image: url("checkout/images/logo_bg.svg");
      //background-size: cover;
      //background-repeat: no-repeat;
      width: 96px;
      height: 48px;
    }

    #checkout-header-logo {
      background: url('checkout/images/logo.svg') no-repeat center;
      background-size: contain;
      height: 48px;
      z-index: 999;
      margin-top: 15px;
    }

    #checkout-header-logo-small {
      background: url('checkout/images/logo_small.svg') no-repeat center;
      background-size: contain;
      height: 30px;
      width: 90px;
      margin: 13px 0 0 0;
      z-index: 100;
    }
  }

  &.BRAND {
    margin-bottom: 4px;
    box-shadow: 0 6px 6px -6px rgba(0,0,0,0.20);

    #checkout-header {
      display: flex;
      background: none;
      background-color: $white;

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        display: block;
        top: 0;
        left: 100%;
        background: $white;
      }

      #checkout-header-logo-bg {
        background-image: none;
      }

      #checkout-header-logo {
        @include svg-background('checkout/images/logo_DWind.svg', 100px);
      }

      #checkout-header-logo-small {
        @include svg-background('checkout/images/logo_DWind.svg', 94px);
      }
    }
  }
}

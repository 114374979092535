.checkout-step-payment {
	cursor: pointer;
	.disabledOnRedirect{
		opacity: 0.5;
		pointer-events: none;
	}
	&-type {
		padding: 20px;
		box-shadow: 10px 11px 33px 0 rgba(0, 0, 0, 0.1);
		background: white;
		border-radius: 6px;
		margin-bottom: 30px;
		border: 1px solid transparent;
		md-radio-button {
			margin-bottom: 0;
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			justify-content: space-between;
			.md-container {
				position: relative;
				top: initial !important;
				transform: unset;
			}
			.md-label {
				margin-left: 0;
				width: auto;
			}
		}
		&-content {
			align-items: center;
		}
		&.active {
			border-color: $secondary-color;
		}
	}

	.widi-input.input-expire-date {
		.md-input-messages-animation {
			position: absolute;
			top: 30px;
		}
	}
	.checkout-step-payment-type-text {
		&.tied {
			font-size: 12px;
			font-style: italic;
		}
	}

	.checkout-payment-switch-payment {
		font-size: 0.85em;
		margin-bottom: 20px;
		@include font-bold;
	}

	#checkout-step-payment-saved-payments {
		margin-bottom: 50px;
	}

	.md-label {
		width: 95%;

		.checkout-step-payment-payment-info {
			margin-left: 15px;
			padding-right: 20px;
		}
	}

	.widi-form-component {
		&.widi-input {
			margin-bottom: 30px;
		}

		&.widi-select {
			margin-bottom: 20px;
		}
	}

	.liquid-child,
	.liquid-container {
		overflow: visible;
		z-index: 9;
	}

	.checkout-step-payment-type-title {
		b {
			text-transform: capitalize;
		}
	}

	.widi-submit {
		.md-button {
			position: static;
		}
	}
	.checkout-step-navigation {
		.paypal-container {
			background: #0070ba;
			border-radius: 25px;
			box-sizing: border-box;
			color: $white;
			@include font-bold;
			font-size: 14px;
			height: 50px;
			align-items: center;
			justify-content: center;
			text-transform: uppercase;
			position: relative;
			display: flex;
			&.disabledButton {
				opacity: 0.6;
				pointer-events: none;
			}
			.icon {
				width: 24px;
				height: 24px;
				margin-right: 10px;
				background-size: cover;
				background: url("checkout/images/payment_icons/paypal_icon.svg") no-repeat center;
			}
			.checkout-paypal-payment {
				opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
			}
		}
		.redirectSubmitButton {
			background: linear-gradient(
				90deg,
				$primary-color 0%,
				$primary-color--dark 100%
			) !important; //NEW $secundary-color
			border: 1px solid $primary-color; //NEW $secundary-color
			color: $white !important;
			font-size: 12px;
		}
	}
	.widi-form-fieldset {
		width: 100%;

		.widi-form-fieldset-title {
			.subtitle {
				text-transform: initial;
				font-weight: 400;
				font-size: 14px;
				margin-top: 10px;
			}
			.checkout-step-payment-saved-payments-description {
				text-transform: none;
				font-size: 0.875em;
				margin-top: 10px;
			}
		}

		&.checkout-step-payment-instalments {
			margin-top: 45px;

			.checkout-step-payment-instalments-choice {
				margin-bottom: 25px;
			}
		}
		&.checkout-step-payment-activation-inner {
			padding-left: 30px;
			.inner-title{
				margin-bottom: 20px;
				font-size: 12px;
			}
			.checkout-step-payment-activation-choice {
				//margin-bottom: 25px;
			}
		}
	}
	#tied {
		&-info,
		&-activation {
			margin-bottom: 60px;
			.asterisk {
				.widi-form-fieldset-title {
					&::after {
						content: '*';
					}
				}
			}
		}
	}

	.tryAndBuyInfo{
		font-size: 12px;
		margin-bottom: 30px;
		&::before {
			content: '*';
		}
	}
}

.checkout-step-storechoice {
	.widi-form-fieldset {
		min-height: 400px;
	}
	&-title {
		font-size: 16px;
		margin-bottom: 30px;
	}
	&-choice {
		padding: 20px;
		box-shadow: 10px 11px 33px 0 rgba(0, 0, 0, 0.1);
		background: white;
		border-radius: 6px;
		margin-bottom: 30px;
		border: 1px solid transparent;
		
		&-content {
			display: flex;
			align-items: center;
		}
		&-icon {
			margin-right: 16px;
		}
		&-title {
			font-weight: 700;
			font-size: 1em;
		}
		&-text {
			font-size: 1em;
			.address {
				text-transform: capitalize;
			}
		}
		&-cta {
			padding-top: 20px;
			text-align: right;
			.cta {
				font-size: 16px;
				font-weight: 700;
				text-decoration: underline;
				color: $secondary-color;
				position: relative;
				margin-right: 20px;
				padding-right: 12px;
				cursor: pointer;
				&::after {
					display: block;
					width: 20px;
					height: 20px;
					content: '';
					position: absolute;
					left: 100%;
					top: 0;
					background: url("checkout/images/ic_arrow_purple.svg") no-repeat center;
					background-size: contain;
				}
			}
		}
		&.active {
			border-color: $secondary-color;
		}
	}
}

md-dialog {
	max-width: 95%;
	max-height: 95%;
	.md-dialog-content {
		&.map-dialog {
			max-width: unset;
			height: 100%;
			display: flex;
			padding: 0;
      border: none;
			.checkout-dialog-box {
				flex: 1;
        margin: 10px 0 0 0;
			}
			.md-dialog-content {
				padding: 0;
				overflow: hidden;
				min-height: 90vh;
			}
			.checkout-dialog-close {
				position: absolute;
				right: 10px;
				top: 10px;
			}
		}
		.iframe-title {
			font-size: 18px;
			font-weight: 700;
			text-align: center;
			margin-top: 10px;
			margin-bottom: 20px;
		}
		.iframeStoreContainer {
			display: flex;
			flex-direction: column;
			flex: 1;
		}
		#iframeStore {
			height: 500px;
			min-width: 70vw;
			overflow: hidden;
			outline: none;
			border: none;
		}
	}
}


.test,
.test2 {
	display: contents;
}

$subheader-line-height: 1em !default;
$subheader-font-size: rem(1.4) !default;
$subheader-padding: ($baseline-grid * 2) !default;
$subheader-font-weight: 500 !default;
$subheader-margin: 0 0 0 0 !default;
$subheader-sticky-shadow: 0px 2px 4px 0 rgba(0,0,0,0.16) !default;

@keyframes subheaderStickyHoverIn {
  0% {
    box-shadow: 0 0 0 0 transparent;
  }
  100% {
    box-shadow: $subheader-sticky-shadow;
  }
}
@keyframes subheaderStickyHoverOut {
  0% {
    box-shadow: $subheader-sticky-shadow;
  }
  100% {
    box-shadow: 0 0 0 0 transparent;
  }
}

.md-subheader-wrapper {

  &:not(.md-sticky-no-effect) {
    .md-subheader {
      margin: 0;
    }

    transition: 0.2s ease-out margin;

    &.md-sticky-clone {
      z-index: 2;
    }

    &[sticky-state="active"] {
      margin-top: -2px;
    }

    &:not(.md-sticky-clone)[sticky-prev-state="active"] .md-subheader-inner:after {
      animation: subheaderStickyHoverOut 0.3s ease-out both;
    }
  }

}

.md-subheader {
  display: block;
  font-size: $subheader-font-size;
  font-weight: $subheader-font-weight;
  line-height: $subheader-line-height;
  margin: $subheader-margin;
  position: relative;

  .md-subheader-inner {
    display: block;
    padding: $subheader-padding;
  }

  .md-subheader-content {
    display: block;
    z-index: 1;
    position: relative;
  }
}

@import 'widi-uikit';
@import 'checkout';

#wwwind-cookies {
  align-content: center;
  align-items: center;
  background: rgba(41, 47, 54, 0.9);
  bottom: 0;
  color: white;
  display: flex;
  flex-direction: row;
  font-size: 0.9em;
  justify-content: flex-start;
  left: 0;
  padding: 10px 10%;
  position: fixed;
  z-index: 200;

  a {
    color: white;
    text-decoration: underline;
  }

  #wwwind-cookies-close {
    font-size: 2em;
    cursor: pointer;
    padding: 0 0 15px 15px;
  }
}

.checkout-step-contract {
  font-size: 0.858em;

  .checkout-step-contract-tos-item-control {
    .widi-checkbox-label {
      font-size: 1em;
    }
  }
  .termsAndConditionsBlock{
    .widi-form-fieldset-title{
      margin-bottom: 10px;;
    }
  }
  .contract_download {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .contractConditionsAccepted{
      flex: 1;
      md-checkbox {
        margin-bottom: 0;
      }
    }
    .download-button {
      text-align: right;
      cursor: pointer;
      text-decoration: underline;
      color: $secondary-color;
      position: relative;
      padding-right: 10px;
      font-weight: 700;
      margin-right: 20px;
      &::after{
        display: block;
        width: 20px;
        height: 22px;
        content: '';
        position: absolute;
        left: 100%;
        top: 0;
        background: url("checkout/images/ic_download.svg") no-repeat center;
        background-size: contain;
      }
    }
  }
  .tos-main-detail {
    margin: 10px 0 0;

    md-checkbox {
      margin-bottom: 0;
    }
  }
  .conditions-tooltip{
    position: relative;
  }
  .generalConditionsTooltip, .generalConditionsTooltipFDO {
    .checkout-tooltip-text{
      top: 265px;
    }
  }



  md-radio-button {
    margin-bottom: 30px;
  }

  .widi-form .widi-checkbox md-checkbox span label {
    font-size: 1em;
  }

  .checkout-step-contract-mnp-choice-title {
    font-size: 1.17em;
    @include font-bold;
  }

  .checkout-step-contract-mnp-choice-text {
    font-size: 1.17em;
  }

  .contract-step-tos-title{
    @include font-bold;
    margin-bottom: 30px;
    font-size: 1.15em;
    text-transform: uppercase;
  }

  #checkout-step-contract-tos-details {
    .checkout-step-contract-tos-toggle {
      text-align: right;
      cursor: pointer;
      text-decoration: underline;
      color: $secondary-color; // NEW $secundary-color
      margin-bottom: 20px;
      margin-right:20px;
      position: relative;
      padding-right: 10px;
      &::after{
        display: block;
        width: 16px;
        height: 18px;
        content: '';
        position: absolute;
        left: 100%;
        top: 0;
        background: url("checkout/images/show-consents.svg") no-repeat center;
        background-size: contain;
      }
      &.tos-details-open{
        &::after{
          background: url("checkout/images/hide-consents.svg") no-repeat center;
        }
      }
    }

    .checkout-step-contract-tos-content {
      padding-left: 30px;
      margin-bottom: 35px;
    }

    .checkout-step-contract-tos-item {
      margin: 10px 0;

      md-checkbox {
        margin-bottom: 5px;
      }

      .checkout-step-contract-tos-item-text {
        color: $black; // NEW $black
        @include font-regular;
        margin: 0 0 10px;
      }
    }
  }

  .animateUpDown {
    &:not(.liquid-animating) {
      overflow: visible;
      z-index: 1;
    }

    .liquid-child {
      overflow: visible;
    }
  }

  p {
    color: $black; // NEW $black
    @include font-regular;
  }
}

#checkout-step-contract-iccid-toggle {
  @include svg-background('checkout/images/ic_iccid.svg', 20px);
}

.checkout-dialog {
    &-icon{
      &.checkout-step-contract-iccd-dialog-icon-sim {
        background-image: url("checkout/images/img_sim_iccid.jpg");
        height: 82px!important;
        width: 126px!important;
      }

      &.checkout-step-contract-iccd-dialog-icon-ios {
        background-image: url("checkout/images/img_iOS_iccid.png");
        height: 58px !important;
        width: 154px!important;
      }
    }
}

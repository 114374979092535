// See height set globally, depended on by buttons

$md-toast-content-padding: 3 * $baseline-grid - $button-left-right-padding !default;
$md-toast-button-left-margin: 3 * $baseline-grid - 2 * $button-left-right-padding !default;
$md-toast-text-padding: $button-left-right-padding !default;


.md-toast-text {
  padding: 0 $md-toast-text-padding;
}

md-toast {
  position: absolute;
  z-index: $z-index-toast;

  box-sizing: border-box;
  cursor: default;
  overflow: hidden;

  // Add some padding to the outer toast container so that the wrapper's box shadow is visible
  padding: $toast-margin;

  // Setup opacity transition on whole toast
  opacity: 1;
  transition: $swift-ease-out;

  .md-toast-content {
    display: flex;
    flex-direction: row;
    align-items: center;

    max-height: 7 * $toast-height;
    max-width: 100%;

    min-height: 48px;
    // Since we're vertically centering our text by using flexbox and having a min-height, we need to apply
    // a fix for an IE11 flexbug, otherwise the text won't be centered vertically.
    @include ie11-min-height-flexbug(48px);

    padding: 0 $md-toast-content-padding;

    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
    border-radius: 2px;
    font-size: 14px;

    overflow: hidden;

    // Setup for transform transitions on inner content
    transform: translate3d(0, 0, 0) rotateZ(0deg);
    transition: $swift-ease-out;

    @include rtl(justify-content, flex-start, flex-end);

    span {
      // Layout  [flex]
      flex:1 1 0%;
      box-sizing:border-box;
      min-width :0;
    }
  }

  &.md-capsule {
    border-radius: 24px;

    .md-toast-content {
      border-radius: 24px;
    }
  }

  &.ng-leave-active {
    .md-toast-content {
      transition: $swift-ease-in;
    }
  }

  /* Transition differently when swiping */
  &.md-swipeleft,
  &.md-swiperight,
  &.md-swipeup,
  &.md-swipedown {
    .md-toast-content {
      transition: $swift-ease-out;
    }
  }

  &.ng-enter {
    opacity: 0;
    .md-toast-content {
      transform: translate3d(0, 100%, 0);
    }
    &.md-top {
      .md-toast-content {
        transform: translate3d(0, -100%, 0);
      }
    }
    &.ng-enter-active {
      opacity: 1;
      .md-toast-content {
        transform: translate3d(0, 0, 0);
      }
    }
  }
  /*
   * When the toast doesn't take up the whole screen,
   * make it rotate when the user swipes it away
   */
  &.ng-leave.ng-leave-active {
    .md-toast-content {
      opacity: 0;
      transform: translate3d(0, 100%, 0);
    }

    &.md-swipeup {
      .md-toast-content {
        transform: translate3d(0, -50%, 0);
      }
    }
    &.md-swipedown {
      .md-toast-content {
        transform: translate3d(0, 50%, 0);
      }
    }
    &.md-top {
      .md-toast-content {
        transform: translate3d(0, -100%, 0);
      }
    }
  }

  .md-action {
    line-height: 19px;
    margin-left: 24px;
    margin-right: 0;
    cursor: pointer;
    text-transform: uppercase;
    float: right;
  }

  .md-button {
    min-width: 0;
    @include rtl(margin-right, 0, $md-toast-button-left-margin);
    @include rtl(margin-left, $md-toast-button-left-margin, 0);
  }
}

@media (max-width: $layout-breakpoint-sm - 1) {
  md-toast {
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    min-width: 0;
    border-radius: 0;
    bottom: 0;
    padding: 0;

    &.ng-leave.ng-leave-active {
      &.md-swipeup {
        .md-toast-content {
          transform: translate3d(0, -50%, 0);
        }
      }
      &.md-swipedown {
        .md-toast-content {
          transform: translate3d(0, 50%, 0);
        }
      }
    }
  }
}

@media (min-width: $layout-breakpoint-sm) {
  md-toast {
    min-width: 288px + $toast-margin * 2;
    &.md-bottom {
      bottom: 0;
    }
    &.md-left {
      left: 0;
    }
    &.md-right {
      right: 0;
    }
    &.md-top {
      top: 0;
    }

    // Support for RTL alignment
    &._md-start {
      @include rtl-prop(left, right, 0, auto);
    }

    &._md-end {
      @include rtl-prop(right, left, 0, auto);
    }

    /*
   * When the toast doesn't take up the whole screen,
   * make it rotate when the user swipes it away
   */
    &.ng-leave.ng-leave-active {
      &.md-swipeleft {
        .md-toast-content {
          transform: translate3d(-50%, 0, 0);
        }
      }
      &.md-swiperight {
        .md-toast-content {
          transform: translate3d(50%, 0, 0);
        }
      }
    }
  }
}

@media (min-width: $layout-breakpoint-lg) {
  md-toast {
    .md-toast-content {
      max-width: $baseline-grid * 71;
    }
  }
}

@media screen and (-ms-high-contrast: active) {
  md-toast {
    border: 1px solid #fff;
  }
}


// While animating, set the toast parent's overflow to hidden so scrollbars do not appear
.md-toast-animating {
  overflow: hidden !important;
}

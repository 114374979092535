#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 120px;
  height: 58px;
  margin: -29px 0 0 -60px;
  z-index: 9999;
  -webkit-animation: drawlogo 10s linear infinite;
  animation: drawlogo 10s linear infinite; // #loader-wrapper
  stroke-dasharray: 150;
  stroke-linecap: round;
  fill: transparent;
  stroke: #FC4A14;

  &-wrapper {
    opacity: 0.75;
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .skip-loading {
      color: $black;
      position: absolute;
      z-index: 9999;
      bottom: 30px;
      right: 30px;
      font-size: 14px;
      font-weight: 700;
      display: none;
      cursor: pointer;
    }

    .loader-section {
      position: fixed;
      top: 0;
      width: 100%;
      height: 100%;
      background: $white;
      z-index: 850;

      &.section-full {
        left: 0;
      }
    }
  }
}

@keyframes drawlogo {
  0% {
    stroke-width: 1px;
  }
  60% {
    stroke-width: 1px;
  }
  100% {
    stroke-width: 1px;
    stroke-dashoffset: 1000;
  }
}

/* Loaded */
.loaded {
  .skip-loading {
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }

  #loader,
  #loader-tre {
    opacity: 0;
    -webkit-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }

  #loader-wrapper {
    visibility: hidden;
    -webkit-transform: translateY(-100%);
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
    -webkit-transition: all 0.3s 1s ease-out;
    transition: all 0.3s 1s ease-out;

    .loader-section.section-full {
      opacity: 0;
      -webkit-transition: all 0.3s 0.3s ease-out;
      transition: all 0.3s 0.3s ease-out;
    }

    .loader-section.section-full {
      -webkit-transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    }
  }
}

#tied-info {
  font-size: 12px;
  margin-bottom: 30px;
}

.imageLoader {
  display: block;
  width: 50px;
  height: 50px;
  border: 1px solid transparent;
  border-top-color: $checkout-color-dark-orange;
  border-radius: 50%;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite; // #loader-wrapper

  &_container {
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -25px 0 0 -25px;
    z-index: 300;
    background: $white;
    border-radius: 50%;
    padding: 3px;
  }

  &:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border: 1px solid transparent;
    border-top-color: $primary-color;
    border-radius: 50%;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
  }

  &:after {
    content: "";
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    border: 1px solid transparent;
    border-radius: 50%;
    border-top-color: $checkout-color-light-orange;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
}

body {
  @include font-regular;
  font-size: 0.875em;
  line-height: 1.3;
  background-color: $grey--lightest;
  color: $black;
  display: flex;
  flex-direction: column;
}
input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
md-radio-button .md-container .md-ripple-container {
  display: none;
}
md-radio-button {
  .md-container {
    width: 16px;
    height: 16px;
  }
  .md-on,
  .md-off {
    width: 16px;
    height: 16px;
  }
}

md-radio-button .md-container:before {
  display: none;
}

md-input-container label:not(.md-container-ignore).md-required:after {
  content: "";
}

input:-webkit-autofill {
  background-color: $white !important;
  -webkit-box-shadow: 0 0 0 30px $white inset;
}

#checkout {
  background-color: $white;
  position: relative;

  #checkout-main-data-section {
    position: relative;
  }

  #checkout-main-cart-section {
    padding-right: 3%;
    .checkout-cart-additionalInfo {
      padding: 12px 12px 12px 20px;
      font-size: 10px;
      position: relative;
      &::before {
        position: absolute;
        top: 10px;
        left: 5px;
        font-size: 20px;
        display: block;
        content: "\002A";
      }
    }
  }

  #checkout-footer-help-mobile {
    display: none;
  }

  #checkout-error,
  #checkout-identification,
  #checkout-main {
    margin: 30px 0;
  }

  .checkout-login-cta {
    display: none;

    font-size: 0.85em;
    margin-top: -45px;
    margin-bottom: 30px;
    margin-left: 3%;
    padding: 0 15px;

    a {
      color: $secondary-color;
    }
  }

  #checkout-progress {
    padding: 40px 3%;
    height: 140px;
    display: flex;
  }

  #checkout-main {
    margin-top: 0;
    // background-color: $white;
    margin-bottom: 0;
    //padding-bottom: 30px;
    position: relative;
  }
}

.spin-animate {
  width: 30px;
  height: 30px;
  mask-size: cover;
  -webkit-mask-size: cover;
  background-color: $white;
  mask-image: url("checkout/images/spinner.svg");
  -webkit-mask-image: url("checkout/images/spinner.svg");
  background-repeat: no-repeat;
  -webkit-animation: spin 0.9s infinite linear;
  animation: spin 0.9s infinite linear;
}

@keyframes spin {
  0% {
    transform: scale(1) rotate(0deg);
  }

  100% {
    transform: scale(1) rotate(360deg);
  }
}

md-dialog {
  .widi-form {
    padding-left: 0;
  }

  .dialog-close {
    background-image: url("checkout/images/cross.svg");
    position: absolute;
    height: 20px;
    width: 20px;
    background-repeat: no-repeat;
    right: 10px;
    top: 15px;
    cursor: pointer;
  }
}

.md-dialog-content {
  max-width: 492px;
}

.md-dialog-backdrop {
  z-index: 1000 !important;
}

.md-dialog-container {
  z-index: 1001;
  position: fixed;
}

.widi-calendar-dropdown {
  padding: 5px 5px 10px;
  border: 1px solid $grey;
}

.tooltipster-arrow-border {
  border-top-color: $grey--light !important;
}

.tooltipster-arrow-background {
  background-color: $white !important;
}

.widi-input {
  position: relative;
}

.widi-tooltip {
  width: 20px;
  position: absolute;
  height: 20px;
  right: 8px;
  top: 8px;
}

.checkout-dialog {
  background-color: $white;
  color: $black; // NEW $black
  border: 1px solid $grey--light; // NEW $grey--light
  @include font-regular;

  .checkout-dialog-title {
    @include font-bold;
    font-size: 1.1em;
    margin: 10px 0 20px;
  }

  .checkout-dialog-close {
    @include svg-background("checkout/images/ic_close_popup.svg", 20px);
    cursor: pointer;
  }

  .checkout-dialog-box {
    margin: 10px 0;
  }

  .checkout-dialog-section {
    margin: 10px 0;
  }

  .checkout-dialog-subtitle {
    font-size: 0.875em;
    margin-bottom: 5px;
  }

  .checkout-dialog-text {
    @include font-regular;
    font-size: 0.875em;
  }

  .checkout-dialog-icon {
    background-repeat: no-repeat;
    background-position: right;
  }
}

md-input-container {
  .md-char-counter {
    display: none;
  }

  .paper-input-error {
    display: none;

    &:nth-child(1) {
      display: block;
    }
  }
}

md-progress-linear {
  & .md-container,
  &.md-default-theme .md-container {
    background-color: $grey--light; // NEW $grey--light
    border-radius: 6px;
  }

  & .md-bar,
  &.md-default-theme .md-bar {
    background-color: $alert--green; // NEW $secundary-color
  }
}

.md-input {
  @include font-regular;
}

.widi-form {
  padding-left: 80px;
  margin-bottom: 30px;

  .widi-form-component {
    margin-bottom: 50px;
  }

  md-input-container {
    .md-input {
      border-bottom-width: 1px !important;
      border-bottom-color: $black !important; // NEW $secundary-color
    }

    &.md-input-has-value {
      .md-input {
        border-bottom-color: $black !important; // NEW $secundary-color
      }
    }

    &.md-input-focused {
      .md-input {
        border-bottom-color: $black !important; // NEW $secundary-color
      }
    }

    &.md-input-invalid {
      .md-input {
        border-width: 0 0 2px 0 !important;
        border-bottom-color: $alert--red !important; // NEW $alert--red
      }
      .md-input-message-animation {
        color: $alert--red;
      }
    }
  }

  .widi-form-fieldset {
    .widi-form-fieldset-title {
      margin: 0 0 30px;
      text-transform: uppercase;
      @include font-bold;
      font-size: 1.15em;
    }

    .widi-form-fieldset-content {
      margin: 20px 0;
    }
  }

  .widi-checkbox {
    width: 90%;
    max-width: 380px;
    md-checkbox {
      .md-container,
      .md-icon {
        width: 20px;
        height: 20px;
        //top: 4px !important;
      }

      .md-icon {
        border-radius: 5px;
      }

      &.md-default-theme:not(.md-checked) .md-icon,
      &:not(.md-checked) .md-icon {
        border: 1px solid $secondary-color; // NEW $secundary-color
      }

      &.md-checked .md-icon,
      &.md-default-theme.md-checked .md-icon {
        background-color: $secondary-color; // NEW $secundary-color
      }

      &.md-checked .md-icon:after,
      &.md-default-theme.md-checked .md-icon:after {
        top: 0;
        left: 5px;
      }

      &.md-checked .md-ink-ripple {
        color: transparent;
      }

      span {
        display: table;

        label {
          display: table-cell;
          vertical-align: middle;
          color: $black; // NEW $black
          @include font-regular;
          cursor: pointer;
          font-size: 0.85em;
        }
      }
    }
  }

  .widi-autocomplete,
  .widi-date,
  .widi-input,
  .widi-input-confirm {
    md-input-container {
      margin: 1px;

      input {
        padding: 0 5px;
      }

      .md-input {
        height: 34px;
        border-bottom-width: 1px !important;
        border-bottom-color: $black !important; // NEW $secundary-color
      }

      label:not(.md-no-float):not(.md-container-ignore) {
        overflow: inherit;
      }

      .md-input:read-only {
        color: rgba(0, 0, 0, 0.5);
      }

      &.md-input-focused {
        label {
          color: $grey; // NEW $secundary-color
          overflow: inherit;
          font-size: 14px;
        }
        .md-input {
          border-bottom-color: $black !important; // NEW $secundary-color
        }
      }

      &.md-input-has-value {
        label {
          color: $grey; // NEW $secundary-color
          overflow: inherit;
          font-size: 12px;
        }
        .md-input {
          border-bottom-color: $black !important; // NEW $secundary-color
        }
      }

      &.md-input-invalid,.md-input-invalid {
        label {
          color: $alert--red; // NEW $alert--red
          overflow: inherit;
          font-size: 12px;
        }
        .md-input {
          border-width: 0 0 2px 0 !important;
          border-bottom-color: $alert--red !important; // NEW $alert--red
          border-color: $alert--red;
        }
        .md-input-message-animation {
          color: $alert--red;
        }
      }

      &.md-input-has-value[disabled="disabled"] {
        color: $grey; // NEW $secundary-color--light
        label {
          color: $grey !important; // NEW $secundary-color--light
        }
        .md-input {
          border-bottom-color: $grey !important; // NEW $secundary-color--light
          cursor: not-allowed;
        }
      }
    }
    &.checkout-input-with-tooltip {
      .md-input {
        width: 90%;
      }
    }
  }

  .widi-form-component-error,.md-input-invalid {
    .md-input-messages-animation {
      position: absolute;
      bottom: -17px;

      .paper-input-error {
        font-size: 0.8em;
        padding: 0;
        white-space: nowrap;
        margin-left: 8px;
      }
    }
  }

  md-input-container {
    display: flex;
    padding: 0;
    margin: 0;

    label {
      color: $grey; // NEW $secundary-color
      line-height: 22px;
    }

    input {
      padding: 5px;
    }

    &.md-focused {
      label {
        margin-left: 0;
      }
    }

    &.md-has-value {
      label {
        padding-left: 0;
        margin-left: 0;
        font-size: 1.1em;
        margin-bottom: 3px;
      }
    }
  }

  md-autocomplete {
    border-radius: 2px;
    display: block;
    position: relative;
    overflow: visible;
    padding: 0;
    min-width: unset;
  }

  .md-autocomplete-suggestions-container {
    position: inherit;
    border-right: 1px solid;
    border-left: 1px solid;
    border-bottom: 1px solid;
    border-color: $black; // NEW $secundary-color
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    margin-top: -2px;

    .ember-power-select-options {
      max-height: 225px;

      .ember-power-select-option {
        height: 35px;
        line-height: 35px;
        border-top: 1px solid $grey; // NEW $secundary-color

        &:hover {
          background-color: $primary-color; // NEW $secundary-color
          color: $white;
        }
      }
    }
  }
  md-checkbox .md-container .md-ripple-container {
    display: none;
  }
  md-progress-linear {
    &.md-inline {
      bottom: -1px !important;
    }

    .md-bar {
      background-color: $alert--green; // NEW $secundary-color
    }
  }

  .ct-dropdown {
    border-radius: 0;
  }

  md-virtual-repeat-container {
    position: absolute;
    width: 99%;
    margin-left: 2px;
    max-height: 196px;

    .md-virtual-repeat-scroller {
      overflow-x: unset;
    }

    .ct-options {
      .ct-option {
        height: 24px;
        line-height: 22px;

        &:hover {
          background-color: $secondary-color--light;
        }

        &[aria-current="true"] {
          background-color: $secondary-color;
          color: $white;
        }
      }
    }
  }

  .widi-calendar-dropdown {
    position: absolute;
  }

  .ember-power-select-trigger {
    border: none;

    &:focus {
      outline: none;
    }

    &[aria-disabled="true"] {
      background-color: $white;
      cursor: not-allowed;
      .ember-power-select-selected-item {
        color: $grey--light !important; // NEW $secundary-color--light
      }
    }
  }

  .ember-power-select-trigger--active {
    border: none;
  }

  .widi-select {
    border: 1px solid $black; // NEW $secundary-color
    border-radius: 4px;
    position: relative;

    .widi-select-arrow {
      pointer-events: none;
      z-index: 100;
      height: 16px;
      width: 16px;
      position: absolute;
      right: 10px;
      top: 8px;
      background-image: url("checkout/images/dropdown_arrow_down.svg");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;

      &.open {
        background-image: url("checkout/images/dropdown_arrow_up.svg");
      }
    }

    .hidden-form-input {
      input {
        display: none;
      }
    }

    .ember-power-select-placeholder,
    .ember-power-select-selected-item {
      line-height: 30px;
      color: $black; // NEW $black
    }
  }

  .widi-select {
    .ember-power-select-dropdown {
      border: 0;
    }
    .ember-basic-dropdown {
      .ember-basic-dropdown-content {
        border-color: $black !important; // NEW $secundary-color
        top: 30px;
        width: 100%;
        left: 0;
        box-shadow: 0 18px 30px 0 rgba(0, 0, 0, 0.3);

        .ember-power-select-options {
          .ember-power-select-option {
            border-top: 1px solid $grey; // NEW $secundary-color
            padding: 5px;

            &[aria-selected="true"] {
              border-top: 1px solid $primary-color;
              background-color: $white;
              color: $black; // NEW $black
            }

            &[aria-current="true"] {
              border-top: 1px solid $primary-color;
              background-color: $primary-color; // NEW $secundary-color
              color: $white; // NEW $white
            }

            &:active {
              background-color: $primary-color; // NEW $secundary-color
              color: $white; // NEW $white
            }
          }
        }
      }
    }

    md-input-container {
      .md-errors-spacer {
        min-height: 0;
      }

      &.hidden-form-input {
        height: 0;

        input {
          height: 0;
          padding: 0;
        }
      }
    }

    .md-input-messages-animation {
      bottom: -17px;
    }

    &.widi-form-component-error {
      border: 2px solid $alert--red; // NEW $alert--red

      &.widi-select {
        border: 1px solid $alert--red; // NEW $alert--red
      }

      .ember-basic-dropdown-content {
        border-color: $alert--red !important; // NEW $alert--red
        border-width: 1px;
      }

      .ember-power-select-placeholder {
        color: $alert--red; // NEW $alert-red
      }
    }
  }

  .md-button {
    border-radius: 40px;
    @include font-bold;
    margin: 0;
    padding: 0;

    &:not([disabled]).md-focused {
      background-color: transparent;
    }

    &:hover {
      background-color: transparent;
    }

    &.md-raised {
      &:not([disabled]) {
        box-shadow: none;

        &:active,
        &:focus,
        &:hover {
          background: linear-gradient(
            90deg,
            $primary-color 0%,
            $primary-color--dark 100%
          ); // NEW $secundary-color
        }
      }

      &:disabled {
        background: linear-gradient(
          90deg,
          $primary-color 0%,
          $primary-color--dark 100%
        ); // NEW $secundary-color--light
        opacity: 0.3;
        color: $white;
      }
    }
  }

  .widi-submit {
    .md-button {
      width: 100%;
      height: 50px;
      background: linear-gradient(
        90deg,
        $primary-color 0%,
        $primary-color--dark 100%
      ); // NEW $secundary-color
      color: $white; // NEW $white
      font-size: 14px;
      @include font-bold;

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }
  }
}

a {
  color: $secondary-color; // NEW $secundary-color
}

#checkout-progress {
  //background-color: $white;

  #checkout-progress-title {
    font-size: 1em;
    margin: 7px 0;
    font-weight: 600;
  }
}

.checkout-error-title {
  color: $black; // NEW $black
  padding-right: 15px;
  font-size: 1.3em;
  @include font-bold;
  margin-bottom: 20px;
}

.modal-close {
  background-image: url("checkout/images/cross.svg");
  background-size: 20px;
  position: absolute;
  height: 20px;
  width: 20px;
  background-repeat: no-repeat;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.checkout-error {
  padding: 0 30px;

  .checkout-error-title {
    color: $primary-color;
    font-size: 60px;
    margin-top: 20px;
  }

  .checkout-error-detail {
    font-size: 1.2em;
    margin: 10px 0;

    .checkout-error-detail-title {
      @include font-bold;
      font-size: 18px;
    }

    .checkout-error-detail-promo-title {
      margin-top: 60px;
      color: $black;
      @include font-bold;
      font-size: 28px;
      line-height: 1.6em;
    }

    .checkout-error-detail-promo-subtitle {
      font-size: 18px;
    }
    .checkout-error-detail-button-subtitle {
      margin-top: 30px;
      font-size: 18px;
    }
  }
  &.error_payment_redirect {
    .checkout-error-detail-promo-title {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
  .widi-button {
    @include font-bold;
    background: linear-gradient( 90deg,$primary-color 0%, $primary-color--dark 100%);
    border: 1px solid $primary-color;
    border-radius: 60px;
    color: $white;
    display: inline-block;
    margin: 30px 10px 0 0;
    padding: 15px;
    line-height: 1;
    text-align: center;
    width: 100%;
    height: 50px;
    max-width: 320px;

    &.empty-button {
      color: $primary-color;
      @supports (-webkit-background-clip: text) and
        (-webkit-text-fill-color: transparent) {
        background-image: -webkit-linear-gradient(
          0deg,
          $primary-color,
          $primary-color--dark 20%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      background-color: transparent;
      &:hover {
        background-color: transparent;
      }
    }

    &:hover {
      background-color: $primary-color;
    }
  }
}

#checkout {
  max-width: 1024px;
  width: 100%;
  margin: 20px auto 60px; // NEW margin: 20px auto;

  .blue-link {
    color: $secondary-color; // NEW $secundary-color
    cursor: pointer;
  }

  md-radio-button {
    .md-label {
      cursor: pointer;
    }

    .md-off {
      border: 2px solid $secondary-color; // NEW $secundary-color
    }

    .md-on {
      background-color: $secondary-color; // NEW $secundary-color
    }

    &.md-checked,
    &.md-primary {
      .md-off {
        border: 2px solid $secondary-color; // NEW $secundary-color
      }

      .md-on {
        background-color: $secondary-color; // NEW $secundary-color
      }
    }
    &.md-checked {
      .md-on {
        transform: scale(0.3);
      }
    }
  }
}

.checkout-tooltip {
  .checkout-tooltip-text {
    background: $white; // NEW $secundary-color
    color: $black; // NEW $white
    @include font-bold;
    padding: 15px;
    z-index: 100;
    border-radius: 4px;
    position: absolute;
    font-weight: normal;
    user-select: none;
    box-shadow: 11px 32px 63px 0 rgba(0, 0, 0, 0.3);
    //max-width: 450px;
    left: 82px;
    z-index: 21;
  }
}

.checkout-tooltip-arrow {
  position: absolute;
  top: 2px;
  left: -15px;
  background-size: 100px 100px;
  width: 50px;
  background-color: $white; // NEW $secundary-color
  height: 50px;
  mask-image: url("checkout/images/ic_arrow_up_black.svg");
  mask-size: cover;
  -webkit-mask-size: cover;
  -webkit-mask-image: url("checkout/images/ic_arrow_up_black.svg");
  z-index: 19;
}

.checkout-tooltip-trigger {
  position: absolute;
  top: 8px;
  right: 0;
  @include svg-background("checkout/images/ic_info_black.svg", 20px);
  background-color: $white;
  cursor: pointer;
  margin-left: 2px;
  //z-index: 20;

  &.checkout-tooltip-trigger-active {
    @include svg-background("checkout/images/ic_info_press.svg", 20px);
    cursor: pointer;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 108%;
      left: 3px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 8px 10px 8px;
      border-color: transparent transparent $white transparent; //NEW $secundary-color
      z-index: 99;
    }
    #checkNumber &,
    #checkout-identification-form & {
      &::after {
        display: none;
      }
    }
  }
}

#ckeckout-clock-wrap {
  text-align: right;

  #checkout-clock {
    padding: 0 3% 8px 0;
    color: $black; //NEW $black
    font-size: 11px;
    @include font-regular;
    min-width: 30%;
    display: inline-block;

    .checkout-countdown {
      display: flex;
      justify-content: center;
      align-items: center;

      .checkout-countdown-number {
        display: inline-block;
        @include font-bold;
        color: $primary-color; // NEW $primary-color
        padding-left: 5px;
      }

      .checkout-countdown-bg {
        @include svg-background("checkout/images/icona_timer.svg", 30px);
        display: inline-block;
      }
    }
  }
}

md-dialog .clock-dialog {
  &-content {
    text-align: center;
    line-height: 20px;
    letter-spacing: 0.1px;
    padding: 15px 100px 45px;
    max-width: 600px;

    .checkout-countdown-bg {
      @include svg-background("checkout/images/icona_timer.svg", 40px);
      display: inline-block;
      padding-bottom: 35px;
    }

    .widi-button {
      @include font-bold;
      background: linear-gradient(
        90deg,
        $primary-color 0%,
        $primary-color--dark 100%
      ); //NEW $secundary-color
      border: 1px solid $primary-color; //NEW $secundary-color
      border-radius: 40px;
      color: $white;
      font-size: 12px;
      display: inline;
      line-height: 1.1em;
      margin: 30px auto 0;
      padding: 10px 15px;
      text-align: center;
      max-width: 236px;

      &.empty-button {
        color: $primary-color;
        @supports (-webkit-background-clip: text) and
          (-webkit-text-fill-color: transparent) {
          background-image: -webkit-linear-gradient(
            0deg,
            $primary-color 0%,
            $primary-color--dark 60%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        background-color: transparent;
      }

      &:hover {
        background-color: linear-gradient(
          0deg,
          $primary-color 0%,
          $primary-color--dark 100%
        ); //NEW $secundary-color--light
      }
    }
  }
  &-title {
  }
}

#checkout-main {
  //min-height: 75vh;
  margin: 60px 0;

  #checkout-step-title {
    color: $primary-color; //NEW $primary-color
    @include font-bold;
    font-size: 1.425em;
    margin: 10px 0;
    text-transform: uppercase;
  }

  #checkout-step {

    .widi-button {
      background: transparent;
      border: 1px solid $primary-color; //NEW $secundary-color
      color: $primary-color;
      @supports (-webkit-background-clip: text) {
        background-image: -webkit-linear-gradient(
          0deg,
          $primary-color,
          $primary-color--dark 100%
        );
        -webkit-background-clip: text;
      }
      @include font-bold;
      height: 50px;
      width: 100%;

      &:disabled {
        opacity: 0.3;
        cursor: not-allowed;
      }
    }

    .widi-radio {
      .md-ink-ripple {
        top: 9px;
      }
    }

    .checkout-radio-choice-title {
      @include font-bold;
      font-size: 1em;
    }

    .checkout-radio-choice-text {
      padding-top: 5px;
      font-size: 1em;
    }
  }
}

.full-height {
  height: 100%;
}

.body--hidden {
  opacity: 0 !important;
}


#loader {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  width: 120px;
  height: 58px;
  margin: -29px 0 0 -60px;
  z-index: 9999;
  -webkit-animation: drawlogo 10s linear infinite;
  animation: drawlogo 10s linear infinite; // #loader-wrapper
  stroke-dasharray: 150;
  stroke-linecap: round;
  fill: transparent;
  stroke: #FC4A14;

  &-wrapper {
    opacity: 1;
    z-index: 20000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .skip-loading {
      color: black;
      position: absolute;
      z-index: 29999;
      bottom: 30px;
      right: 30px;
      font-size: 14px;
      font-weight: 700;
      display: none;
      cursor: pointer;
    }

    .loader-section {
      position: fixed;
      top: 0;
      width: 100%;
      height: 100%;
      background: white;
      z-index: 850;

      &.section-full {
        left: 0;
      }
    }
  }
}

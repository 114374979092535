$tooltip-fontsize-lg: 10px !default;
$tooltip-fontsize-sm: 14px !default;
$tooltip-height-lg: 22px !default;
$tooltip-height-sm: 32px !default;
$tooltip-top-margin-lg: 14px !default;
$tooltip-top-margin-sm: 24px !default;
$tooltip-lr-padding-lg: 8px !default;
$tooltip-lr-padding-sm: 16px !default;
$tooltip-max-width: 32px !default;

.md-tooltip {
  pointer-events: none;
  border-radius: 4px;
  overflow: hidden;
  opacity: 0;
  font-weight: 500;
  font-size: $tooltip-fontsize-sm;
  white-space: nowrap;
  text-overflow: ellipsis;
  height: $tooltip-height-sm;
  line-height: $tooltip-height-sm;
  padding-right: $tooltip-lr-padding-sm;
  padding-left: $tooltip-lr-padding-sm;
  &.md-origin-top {
    transform-origin: center bottom;
    margin-top: -$tooltip-top-margin-sm;
  }
  &.md-origin-right {
    transform-origin: left center;
    margin-left: $tooltip-top-margin-sm;
  }
  &.md-origin-bottom {
    transform-origin: center top;
    margin-top: $tooltip-top-margin-sm;
  }
  &.md-origin-left {
    transform-origin: right center;
    margin-left: -$tooltip-top-margin-sm;
  }

  @media (min-width: $layout-breakpoint-sm) {
    font-size: $tooltip-fontsize-lg;
    height: $tooltip-height-lg;
    line-height: $tooltip-height-lg;
    padding-right: $tooltip-lr-padding-lg;
    padding-left: $tooltip-lr-padding-lg;
    &.md-origin-top { margin-top: -$tooltip-top-margin-lg; }
    &.md-origin-right { margin-left: $tooltip-top-margin-lg; }
    &.md-origin-bottom { margin-top: $tooltip-top-margin-lg; }
    &.md-origin-left { margin-left: -$tooltip-top-margin-lg; }
  }

  &.md-show-add {
    transform: scale(0);
  }
  &.md-show {
    transition: $swift-ease-out;
    transition-duration: 150ms;
    transform: scale(1);
    opacity: 0.9;
  }
  &.md-hide {
    transition: $swift-ease-in;
    transition-duration: 150ms;
    transform: scale(0);
    opacity: 0;
  }
}

@mixin font-light {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
}

@mixin font-regular {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}

@mixin font-bold {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
}

$checkout-color-orange: #ff6a00;
$checkout-color-light-orange: #fdb06b;
$checkout-color-dark-orange: #ff3a00;

/* $checkout-color-blue: #2D389C;
$checkout-color-light-blue: #E8EBFF;
$checkout-color-very-light-blue: #AAB2BD;
$checkout-color-dark-blue: #2D389C;
$checkout-color-dark-blue-bg: #0a2161;

$checkout-color-red: #cc0000;

$checkout-color-green: #7ed321;

$checkout-color-dark-grey: #656d78;
$checkout-color-grey: #e6e9ed;
$checkout-color-light-grey: #f4f4f4;
$checkout-color-lighter-grey: #f8f8f8;
$checkout-color-lightest-grey: #fcfcfc;

$checkout-color-very-light-grey: #ccd1d9;
$checkout-color-medium-grey: #ccd1d9;

$checkout-color-black: #292f36;
$checkout-color-white: #ffffff;

$checkout-text-dark: #292f36;
$checkout-text-light: #f4f4f4;

$checkout-text-disabled: #878D97;
 */

// new colors

$primary-color: #ff6900;
$primary-color--dark: #fc4a14;
$secondary-color: #6207ab;
$secondary-color--light: #c19fde;
$black: #000;
$grey: #97999b; 
$grey--medium: #c1c1c1;
$grey--light: #ebebeb;
$grey--lightest: #f8f8f8;
$white: #ffffff;
$alert--red: #f05250;
$alert--green: #84e1b6;
$alert--yellow: #fbb03a;


@mixin svg-background($image, $size) {
  background: url($image) no-repeat center;
  background-size: contain;
  height: $size;
  width: $size;
}

@import 'checkout/style';
@import 'checkout/cart';
@import 'checkout/header';
@import 'checkout/footer';
@import 'checkout/login';
@import 'checkout/identification';
@import 'checkout/steps';
@import 'checkout/responsive';

md-input-container {
  .md-input {

          @include input-placeholder-color(paper-color($foreground, "3"));
          @include input-placeholder-color(var(--foreground-3));
        

          color: paper-color($foreground, "1");
          color: var(--foreground-1);
        

          border-color: paper-color($foreground, "4");
          border-color: var(--foreground-4);
        
  }

  > md-icon {

          color: paper-color($foreground, "1");
          color: var(--foreground-1);
        
  }

  label,
  .md-placeholder {

          color: paper-color($foreground, "3");
          color: var(--foreground-3);
        
  }

  label.md-required:after {

          color: paper-color($warn, "A700");
          color: RGB(var(--warn-A700));
        
  }

  &:not(.md-input-focused):not(.md-input-invalid) label.md-required:after {

          color: paper-color($foreground, "2");
          color: var(--foreground-2);
        
  }

  .md-input-messages-animation, .md-input-message-animation {

          color: paper-color($warn, "A700");
          color: RGB(var(--warn-A700));
        
    .md-char-counter {

          color: paper-color($foreground, "1");
          color: var(--foreground-1);
        
    }
  }

  &.md-input-focused {
    .md-input {

          @include input-placeholder-color(paper-color($foreground, "2"));
          @include input-placeholder-color(var(--foreground-2));
        
    }
  }

  &:not(.md-input-invalid) {
    &.md-input-has-value {
      label {

          color: paper-color($foreground, "2");
          color: var(--foreground-2);
        
      }
    }
    &.md-input-focused,
    &.md-input-resized {
      .md-input {

          border-color: paper-color($primary, "500");
          border-color: RGB(var(--primary-500));
        
      }
    }

    &.md-input-focused {
      label,
      md-icon {

          color: paper-color($primary, "500");
          color: RGB(var(--primary-500));
        
      }
      &.md-accent {
        .md-input {

          border-color: paper-color($accent, "500");
          border-color: RGB(var(--accent-500));
        
        }
        label,
        md-icon {

          color: paper-color($accent, "500");
          color: RGB(var(--accent-500));
        
        }
      }
      &.md-warn {
        .md-input {

          border-color: paper-color($warn, "A700");
          border-color: RGB(var(--warn-A700));
        
        }
        label,
        md-icon {

          color: paper-color($warn, "A700");
          color: RGB(var(--warn-A700));
        
        }
      }
    }
  }
  &.md-input-invalid {
    .md-input {

          border-color: paper-color($warn, "A700");
          border-color: RGB(var(--warn-A700));
        
    }
    label,
    .md-input-message-animation,
    .md-char-counter {

          color: paper-color($warn, "A700");
          color: RGB(var(--warn-A700));
        
    }
  }
  .md-input {
    &[disabled],
    [disabled] & {
      border-bottom-color: transparent;

          color: paper-color($foreground, "3");
          color: var(--foreground-3);
        

          background-image: linear-gradient(to right, paper-color($foreground, "3") 0%, paper-color($foreground, "3") 33%, transparent 0%);
          background-image: linear-gradient(to right, var(--foreground-3) 0%, var(--foreground-3) 33%, transparent 0%);
        

          background-image: -ms-linear-gradient(left, transparent 0%, paper-color($foreground, "3") 100%);
          background-image: -ms-linear-gradient(left, transparent 0%, var(--foreground-3) 100%);
        
    }
  }
}

md-icon {
  margin: auto;
  background-repeat: no-repeat no-repeat;
  display: inline-block;
  vertical-align: middle;
  fill: currentColor;
  height: $icon-size;
  width: $icon-size;

  // The icons should not shrink on smaller viewports.
  min-height: $icon-size;
  min-width: $icon-size;

  svg {
    pointer-events: none;
    display: block;
  }

  &[md-font-icon] {
    line-height: $icon-size;
    width: auto;
  }
}

//
//@font-face {
//  font-family:"material";
//  src:url("/dist/material-font/material.eot");
//  font-weight:normal;
//  font-style:normal;
//}
//
//@font-face {
//  font-family:"material";
//  src:url("/dist/material-font/material.eot");
//  src:url("/dist/material-font/material.eot?#iefix") format("embedded-opentype"),
//    url("/dist/material-font/material.woff") format("woff"),
//    url("/dist/material-font/material.ttf") format("truetype"),
//    url("/dist/material-font/material.svg?#material") format("svg");
//  font-weight:normal;
//  font-style:normal;
//}
//
///* Bootstrap Overrides */
//[class^="icon-"]:before,
//[class*=" icon-"]:before {
//  font-family:"material";
//  display:inline-block;
//  vertical-align:middle;
//  line-height:1;
//  font-weight:normal;
//  font-style:normal;
//  speak:none;
//  text-decoration:inherit;
//  text-transform:none;
//  text-rendering:optimizeLegibility;
//  -webkit-font-smoothing:antialiased;
//  -moz-osx-font-smoothing:grayscale;
//}

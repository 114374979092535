.checkout-login-dialog-content {
  width: 321px;

  .checkout-login-logo {
    @include svg-background('checkout/images/logo.svg', 84px);
  }

  .widi-form-component {
    max-width: 100% !important;

    md-input-container {
      width: 100% !important;
    }
  }

  #checkout-login-error {
    font-size: 0.8em;
    color: $alert--red; // NEW $alert--red
    padding: 20px 0 40px 8px;
  }

  #checkout-login-error-icon {
    background: url("checkout/images/ic_info_error.svg") no-repeat center;
    background-size: contain;
    height: 20px;
    width: 20px;
  }

  .widi-submit {
    button {
      background-color: $secondary-color !important; // NEW $secundary-color
    }
  }

  .checkout-login-dialog-close {
    span {
      float: right;
      @include svg-background('checkout/images/cross.svg', 20px);
      position: absolute;
      background-repeat: no-repeat;
      right: 10px;
      top: 15px;
      cursor: pointer;
    }
  }

  .widi-form {
    padding: 0;
  }
}

.checkout-step-personal {
  position: relative;

  .widi-form-fieldset {
    &#contact {
      position: relative;
    }
  }

  #checkout-step-personal-privacy {
    margin: 20px 0 30px;
    font-size: 0.85em;

    a {
      @include font-bold;
      color: $secondary-color; // NEW $secundary-color
    }

    .label-required-fields {
      margin-top: 30px;
    }
  }
  #gdpr-block {
    font-size: 10px;
    color: rgba($color: $black, $alpha: 0.5);
    margin-bottom: 30px;
  }

  .checkout-step-personal-attentionMessage {
    margin: 0 0 20px 0;
    a {
      color: $black !important;
      text-decoration: none;
    }
  }

  .mobileNumberTooltip {
    .checkout-tooltip-text {
      top: 330px;
    }
  }

  .pecTooltip {
    .checkout-tooltip-text {
      top: 415px;
    }
  }
}

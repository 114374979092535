.md-whiteframe-1dp, .md-whiteframe-z1 {
  box-shadow: $whiteframe-shadow-1dp;
}
.md-whiteframe-2dp {
  box-shadow: $whiteframe-shadow-2dp;
}
.md-whiteframe-3dp {
  box-shadow: $whiteframe-shadow-3dp;
}
.md-whiteframe-4dp, .md-whiteframe-z2{
  box-shadow: $whiteframe-shadow-4dp;
}
.md-whiteframe-5dp {
  box-shadow: $whiteframe-shadow-5dp;
}
.md-whiteframe-6dp {
  box-shadow: $whiteframe-shadow-6dp;
}
.md-whiteframe-7dp, .md-whiteframe-z3 {
  box-shadow: $whiteframe-shadow-7dp;
}
.md-whiteframe-8dp {
  box-shadow: $whiteframe-shadow-8dp;
}
.md-whiteframe-9dp {
  box-shadow: $whiteframe-shadow-9dp;
}
.md-whiteframe-10dp, .md-whiteframe-z4 {
  box-shadow: $whiteframe-shadow-10dp;
}
.md-whiteframe-11dp {
  box-shadow: $whiteframe-shadow-11dp;
}
.md-whiteframe-12dp {
  box-shadow: $whiteframe-shadow-12dp;
}
.md-whiteframe-13dp, .md-whiteframe-z5{
  box-shadow: $whiteframe-shadow-13dp;
}
.md-whiteframe-14dp {
  box-shadow: $whiteframe-shadow-14dp;
}
.md-whiteframe-15dp {
  box-shadow: $whiteframe-shadow-15dp;
}
.md-whiteframe-16dp {
  box-shadow: $whiteframe-shadow-16dp;
}
.md-whiteframe-17dp {
  box-shadow: $whiteframe-shadow-17dp;
}
.md-whiteframe-18dp {
  box-shadow: $whiteframe-shadow-18dp;
}
.md-whiteframe-19dp {
  box-shadow: $whiteframe-shadow-19dp;
}
.md-whiteframe-20dp {
  box-shadow: $whiteframe-shadow-20dp;
}
.md-whiteframe-21dp {
  box-shadow: $whiteframe-shadow-21dp;
}
.md-whiteframe-22dp {
  box-shadow: $whiteframe-shadow-22dp;
}
.md-whiteframe-23dp {
  box-shadow: $whiteframe-shadow-23dp;
}
.md-whiteframe-24dp {
  box-shadow: $whiteframe-shadow-24dp;
}

@media screen and (-ms-high-contrast: active) {
  md-whiteframe {
    border: 1px solid #fff;
  }
}

@media print {
  md-whiteframe, [md-whiteframe] {
    background-color: #ffffff;
  }
}

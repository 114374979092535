$virtual-repeat-scrollbar-width: 16px !default;

.md-virtual-repeat-container {
  box-sizing: border-box;
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;

  .md-virtual-repeat-scroller {
    bottom: 0;
    box-sizing: border-box;
    left: 0;
    margin: 0;
    overflow-x: hidden;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
    -webkit-overflow-scrolling: touch;
  }

  .md-virtual-repeat-sizer {
    box-sizing: border-box;
    height: 1px;
    display: block;
    margin: 0;
    padding: 0;
    width: 1px;
  }

  .md-virtual-repeat-offsetter {
    box-sizing: border-box;
    left: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}

.md-virtual-repeat-container.md-orient-horizontal {
  .md-virtual-repeat-scroller {
    overflow-x: auto;
    overflow-y: hidden;
  }

  .md-virtual-repeat-offsetter {
    // Leave room for the scroll bar.
    // TODO: Will probably need to perform measurements at runtime.
    bottom: $virtual-repeat-scrollbar-width;
    @include rtl-prop(right, left, auto, auto);
    white-space: nowrap;
  }
}

@import 'steps/complete';
@import 'steps/contract';
@import 'steps/customer-identification';
@import 'steps/fork';
@import 'steps/payment';
@import 'steps/paymentcc';
@import 'steps/personal';
@import 'steps/shipping';
@import 'steps/simfork';
@import 'steps/fdoiccid';
@import 'steps/storechoice';


.checkout-step-complete {
  section {
    padding-right: 0 !important;
  }

  #app_banner {
    width: 100%;
    img {
      width: 100%;
    }
  }

  #checkout-step-complete-thankyou {
    line-height: 1.5;
    padding: 10px 40px 0;
    text-align: center;
    margin: 30px 0;

    #checkout-step-complete-thankyou-title {
      @include font-bold;
      font-size: 24px;
    }

    .checkout-step-complete-thankyou-text {
      padding-top: 5px;
      font-size: 18px;
      &.bolder {
        font-weight: bold;
        
      }
      &.pp-text {
        max-width: 470px;
      }
    }
    .checkout-step-complete-thankyou-subtitle {
      color: $black;
      max-width: 470px;
      margin-top:10px
    }

    .checkout-step-complete-thankyou-subtext {
      font-size: 18px;
      padding-top: 10px;
      max-width: 80%;
      &.fdo-thankyou-subtext {
        opacity: 0.75;
        color: #292F36;
        font-size: 12px;
        font-style: italic;
        line-height: 15px;
        text-align: center;
      }
    }
    &.tied{
      #checkout-step-complete-thankyou-title {
        @include font-regular;
        font-size: 24px;
      }
    }
  }



  .checkout-step-complete-section-title {
    background: $primary-color ;
    color: $white;
    font-size: 1.2em;
    @include font-bold;
    padding: 5px 10px;
    margin: 10px 0 20px;
    text-transform: uppercase;
    &.pp-summary-title {
      font-size: 12px;
      color: $primary-color;
      background: none;
      text-align: center;
      padding: 0;
      margin-bottom: 10px;
    }
  }

  .fdo-thankyou-title {
    color: #292F36;
    opacity: 0.5;
    font-size: 30px;
    font-weight: bold;
    line-height: 30px;
  }

  .fdoOffer {
    width: 100%;
    margin-top: 30px;
    .checkout-step-complete-costs-message{
      font-size: 16px;
      text-align: center;
      margin: 0 auto 30px;
      max-width: 380px;
    }
    #checkout-step-complete-summary-pickandpay-code-tied {
      background:$white;
      color: $black;
      font-size: 24px;
      padding: 10px 0;
      text-align: center;
      width: 90%;
      margin: 0 auto 30px;
      max-width: 340px;
      border-radius: 16px;
      box-shadow: 10px 11px 35px 0 rgba(0,0,0,0.3);
      padding: 32px 0;

      span {
        background-color: $grey--lightest;
        display: inline-block;
        font-size: 16px;
        font-weight: bold;
        padding: 18px 0;
        width: 10%;
        max-width: 40px;
        border-radius: 5px;
      }
    }
  }

  #checkout-step-complete-summary {
    margin: 10px 0;
    .tiedOffer {
      .checkout-step-complete-costs-message{
        font-size: 16px;
        text-align: center;
        margin: 0 auto 30px;
        max-width: 380px;
      }
      #checkout-step-complete-summary-pickandpay-code-tied {
        background:$white;
        color: $black;
        font-size: 24px;
        padding: 10px 0;
        text-align: center;
        width: 90%;
        margin: 0 auto 30px;
        max-width: 340px;
        border-radius: 16px;
        box-shadow: 10px 11px 35px 0 rgba(0,0,0,0.3);
        padding: 32px 0;

        span {
          background-color: $grey--lightest;
          display: inline-block;
          font-size: 16px;
          font-weight: bold;
          padding: 18px 0;
          width: 10%;
          max-width: 40px;
          border-radius: 5px;
        }
      }
    }
    .checkout-step-complete-summary-boxes {
      margin: 10px 0 25px;

      #checkout-step-complete-summary-pickandpay-title {
        @include font-bold;
      }

      #checkout-step-complete-summary-pickandpay-code {
        
        color: $black;
        font-size: 28px;
        text-align: center;
        width: 90%;
        margin: 0 auto;
        max-width: 480px;

        span {
          background-color: $grey--lightest;
          display: inline-block;
          font-size: 16px;
          font-weight: bold;
          padding: 18px 0;
          width: 10%;
          max-width: 40px;
          border-radius: 5px;
        }
      }
      &.tied{
        .checkout-step-complete-costs-message{
          font-size: 16px;
          text-align: center;
          margin: 0 auto;
          max-width: 380px;
        }
        #checkout-step-complete-summary-pickandpay-code{
          max-width: 340px;
          font-size: 24px;
        }
      }

      .checkout-step-complete-summary-box {
        .checkout-step-complete-summary-box-title {
          @include font-bold;
          font-size: 1.15em;
        }

        .checkout-step-complete-summary-box-detail {
          margin: 20px 0;

          .checkout-step-complete-summary-box-detail-text {
            margin-left: 10px;

            .checkout-step-complete-summary-box-detail-emphasis {
              @include font-bold;
              font-size: 1.2em;
            }
          }
        }

        .checkout-step-complete-summary-box-text {
          margin-left: 10px;
        }
      }
    }
    #checkout-step-complete-store-info {
      text-align: center;
      font-size: 20px;
      line-height: 1.4;
      margin-bottom: 20px;
      .checkout-step-complete-dealer{
        &-title {
          text-transform: capitalize;
          font-weight: 700;
        }
        &-address {
          text-transform: capitalize;
        }
      }
    }
    .checkout-step-complete-code-sms {
      text-align: center;
      padding: 10px 0 5px;
    }

    #checkout-step-complete-summary-address-icon {
      @include svg-background('checkout/images/icona_pacco.svg', 50px);
    }

    #checkout-step-complete-summary-shipping-icon {
      @include svg-background('checkout/images/ic_delivery.svg', 70px);
    }

    #checkout-step-complete-summary-date-icon {
      @include svg-background('checkout/images/icona_calendario.svg', 50px);
    }
    &.tied{
      .checkout-step-complete-costs-message{
        font-size: 16px;
        text-align: center;
        margin: 0 auto;
        max-width: 380px;
      }
      #checkout-step-complete-summary-pickandpay-code{
        max-width: 340px;
        font-size: 24px;
      }
    }
  }

  #checkout-step-complete-consolidation {
    &-image{
      background-image: url("checkout/images/banner_gigarete.jpg");
      height: 115px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  #checkout-step-complete-bonusBanner{
    padding: 10px;
    img{
      width: 100%;
      max-width: 100%;
      min-height: 180px;
    }
  }

  #checkout-step-complete-instructions {

    margin: 10px 0;

    .checkout-step-complete-summary-boxes {
      margin: 20px 0 40px;

      .checkout-step-complete-instructions-box {
        position: relative;
        padding: 15px;
        margin: 0;
        background-color: $white;
        border-radius: 16px;
        box-shadow: 11px 32px 33px 0 rgba(0, 0, 0, 0.1);

        .checkout-step-complete-instructions-box-header {
          width: 100%;
          .checkout-step-complete-instructions-box-icon {
            &.arrow_left{
              @include svg-background('checkout/images/arrow_Left.svg', 32px);
            }
          }
        }

        .checkout-step-complete-instructions-box-background {
          background-color: $black;
          border-radius: 100%;
          width: 42px;
          min-width: 42px;
          height: 40px;
          line-height: 42px;
          color: #fff;
          margin-right: 10px;
          text-align: center;
          font-size: 1.8em;
          @include font-bold;
        }
        


        .checkout-step-complete-instructions-box-divider {
          border-bottom: 1px solid $grey;
          width: 50px;
          z-index: 1;
        }

        .checkout-step-complete-instructions-box-title {
          @include font-bold;
          margin: 10px 0;
          z-index: 1;
          color: $black;
          font-size: 1.15em;
        }

        .checkout-step-complete-instructions-box-text {
          margin: 10px 0;
          z-index: 1;
        }
      }

      &.checkout-step-complete-instructions-videoidentification {
        .checkout-step-complete-instructions-box {
          padding: 15px 0;

          .checkout-step-complete-instructions-box-title {
            margin: 10px 0;
            color: $black;
          }

          .checkout-step-complete-instructions-box-text {
            margin: 0;
          }
        }
      }
    }
    &.pp-instructions {
      background: #f7f7f7;
      margin-bottom: 0;
      .checkout-step-complete-section-title {
        background: none;
        color: $black;
        text-align: center;
        font-size: 24px;
        text-transform: unset;
        margin-top: 20px;
        margin-bottom: 0;
      }
      .checkout-step-complete-instructions-box {
        padding: 15px;
        margin: 0;
        background-color: transparent;
        border-radius: none;
        box-shadow: none;

        .checkout-step-complete-instructions-box-header {
          justify-content: center;
          align-items: center;
          .checkout-step-complete-instructions-box-icon {
            &.ic_code{
              @include svg-background('checkout/images/ic_code.svg', 32px);
            }
            &.ic_sim{
              @include svg-background('checkout/images/ic_sim.svg', 32px);
            }
            &.ic_id_card{
              @include svg-background('checkout/images/ic_id_card.svg', 32px);
            }
          }
        }
        .checkout-step-complete-instructions-box-title {
          text-transform: uppercase;
          font-size: 16px;
        }

        .checkout-step-complete-instructions-box-text {
         text-align: center;
         max-width: 236px;
         font-size: 16px;
        }
      }
    }

    .infographic{
      background-image: url("checkout/images/info_videoriconoscimento.jpg");
      height: 490px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      &.infographicTied {
        // background-image: url("checkout/images/info_videoriconoscimento_tied.jpg");
      }
    }

    #tutorial-button,#app-button {
      @include font-bold;
      background: linear-gradient(90deg, $primary-color 0%, $primary-color--dark 100%);
      border: 1px solid $primary-color;
      border-radius: 50px;
      color: $white !important;
      @supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
        -webkit-text-fill-color: $white;
      }
      padding: 15px;
      line-height: 1;
      text-align: center;
      width: 236px;
      margin: 15px auto;

      &:hover {
        background-color: linear-gradient(90deg, $primary-color 0%, $primary-color--dark 100%);
      }
    }
    .fdo-instructions {
      font-size: 18px;
      text-align: center;
      margin: 30px 0;
      .fdoIcon{
        .icon{
          width: 80px;
        }
      }
      .text{
        max-width: 520px;
        display: inline-block;
      }
    }


  }

  .checkout-step-complete-instructions-pickandpay {
    text-align: center;
    font-size: 14px;
    max-width: 570px;
    margin: 0 auto;
    border-top: 1px solid #97999B;
    padding: 12px 40px;
    margin-bottom: 20px;
    .text {
      max-width: 480px;
    }
  }
}
.text-bold {
  &-forced{
    font-weight: 700 !important;
  }
}

.checkout-step-fdoiccid {
    &-title {
        font-size: 18px;
        font-weight: 400 ;
        margin-bottom: 50px ;

    }
    .iccid-block {
        position: relative;
        .fixedLabel {
            position: absolute;
            top: -15px;
            font-size: 13px;
            color: $grey;
        }
        .widi-input {
            &.widi-form-component-prefix {
                md-input-container {
                    &.md-input-focused,
                    &.md-input-invalid,
                    &.md-input-has-value {
                        label {
                            display: none;
                        }
                    }
                }
            }
            &.widi-form-component-stepData-fdoIccidSuffix {
                md-input-container {
                    &.md-input-focused,
                    &.md-input-invalid,
                    &.md-input-has-value {
                        label {
                            display: none;
                        }
                    }
                }
            }
            .paper-input-error {
                margin-left: 0;
            }
        }
        .prefix {
            input {
                pointer-events: none;
            }
        }
    }
    
    .iccid-image{
        max-width: 320px;
        margin-left: 30px;
        margin-bottom: 50px;
    }
}
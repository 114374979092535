md-fab-speed-dial {
  position: relative;
  display: flex;
  align-items: center;

  // Include the top/left/bottom/right fab positions and set the z-index for absolute positioning
  @include fab-all-positions();
  z-index: $z-index-fab;

  // Allow users to enable/disable hovering over the entire speed dial (i.e. the empty space where
  // items will eventually appear)
  &:not(.md-hover-full) {
    // Turn off pointer events when closed
    pointer-events: none;

    md-fab-trigger, .md-fab-action-item {
      // Always make the trigger and action items always have pointer events (the tooltip looks
      // for the first parent with pointer-events, so we must set this for tooltips to work)
      pointer-events: auto;
    }

    &.md-is-open {
      // Turn on pointer events when open
      pointer-events: auto;
    }
  }

  ._md-css-variables {
    z-index: $z-index-fab;
  }

  &.md-is-open {
    .md-fab-action-item {
      align-items: center;
    }
  }

  md-fab-actions {
    display: flex;

    // Set the height so that the z-index in the JS animation works
    height: auto;

    .md-fab-action-item {
      transition: $swift-ease-in;
    }
  }

  &.md-down {
    flex-direction: column;

    md-fab-trigger {
      order: 1;
    }

    md-fab-actions {
      flex-direction: column;
      order: 2;
    }
  }

  &.md-up {
    flex-direction: column;

    md-fab-trigger {
      order: 2;
    }

    md-fab-actions {
      flex-direction: column-reverse;
      order: 1;
    }
  }

  &.md-left {
    flex-direction: row;

    md-fab-trigger {
      order: 2;
    }

    md-fab-actions {
      flex-direction: row-reverse;
      order: 1;

      .md-fab-action-item {
        transition: $swift-ease-in;
      }
    }
  }

  &.md-right {
    flex-direction: row;

    md-fab-trigger {
      order: 1;
    }

    md-fab-actions {
      flex-direction: row;
      order: 2;

      .md-fab-action-item {
        transition: $swift-ease-in;
      }
    }
  }

  /*
   * Hide some graphics glitches if switching animation types
   */
  &.md-fling-remove, &.md-scale-remove {
    .md-fab-action-item > * {
      visibility: hidden;
    }
  }

  /*
   * Handle the animations
   */
  &.md-fling {
    .md-fab-action-item {
      opacity: 1;
    }
  }

  // For the initial animation, set the duration to be instant
  &.md-fling.md-animations-waiting {
    .md-fab-action-item {
      opacity: 0;
      transition-duration: 0s;
    }
  }

  &.md-scale {
    .md-fab-action-item {
      transform: scale(0);
      transition: $swift-ease-in;

      // Make the scale animation a bit faster since we are delaying each item
      transition-duration: $swift-ease-in-duration / 2.1;
    }
  }
}

@media (max-width: $layout-breakpoint-xs) {
	.action-blocker {
		#checkout-step,
		#checkout-step input {
			pointer-events: none;
		}
		#checkout-step label,
		#checkout-step input {
			opacity: 0.3;
		}
	}
	#checkout-main-cart-section {
		&.open {
			+ #checkout-main-data-section {
				#videoriconoscimento {
					#checkout-step-shipping-discount-tooltip {
						display: none !important;
					}
				}
			}
		}
	}

	#ckeckout-clock-wrap {
		text-align: center;
		#checkout-clock {
			padding: 5px 0 0 0;
		}
	}

	md-dialog .clock-dialog {
		&-content {
			padding: 15px 30px 45px;
		}
	}

	.checkout-step-shipping {
		.checkout-tooltip-trigger {
			position: absolute;
		}
	}

	#videoriconoscimento {
		#checkout-step-shipping-discount-tooltip {
			background-color: $white; // NEW $secundary-color
			color: $black;
			padding: 6px;
			margin-bottom: 10px;
			position: relative;
			margin-top: -25px;
			display: block !important;
			text-align: center;
			font-size: 0.875em;
			&::before {
				content: "";
				display: block;
				position: absolute;
				bottom: 100%;
				right: 40px;
				width: 0;
				height: 0;
				border-style: solid;
				border-width: 0 10px 10px 10px;
				border-color: transparent transparent $white transparent;
			}
			.checkout-step-shipping-discount-tooltip-body {
				.checkout-step-shipping-discount-tooltip-body-text {
					margin: 0 0 0 5px;
				}
			}
		}
		margin: 30px 0 0 0 !important;
		padding-left: 0 !important;

		.checkout-step-shipping-option {
			height: 125px !important;
			margin-bottom: 20px;
			min-height: 125px !important;

			&.checkout-step-shipping-option-bonus {
				.checkout-step-shipping-selection-box-body {
					bottom: 62px !important;

					.checkout-step-shipping-selection-box-info-img {
						img {
							height: 43px;
						}
					}
				}
				height: 145px !important;

				.checkout-step-shipping-selection-box-sim-discount {
					margin-top: -10px !important;
				}
				.checkout-step-shipping-selection-box-shipping-discount {
					margin-top: 10px !important;
				}
			}
		}
	}

	#checkout-header {
		height: 60px !important;

		#checkout-header-logo-bg {
			/*  width: 130px !important;
      height: 60px !important;
 */
			#checkout-header-logo-small {
				/*height: 60px !important;
        top: -3px !important;*/
			}
		}
	}

	.checkout-login-cta {
		margin-top: -5px !important;
		margin-bottom: 25px !important;
		margin-left: 5% !important;
		padding: 0 !important;

		&.logout {
			position: absolute;
			top: 13px;
			right: 22px;
		}
	}

	.checkout-login-dialog-content {
		width: auto;
	}

	.checkout-login-dialog {
		.widi-form {
			margin: 0;

			.widi-submit {
				padding-top: 30px;
			}
		}
	}

	#checkout-main {
		margin: 0 !important;

		#checkout-main-data-section {
			order: 2;
			-webkit-order: 2;
			padding: 0 20px 20px 20px !important;
		}

		#checkout-main-cart-section {
			padding: 0;
			&.sticky {
				z-index: 999;
				background-color: #fff;
				width: 100%;
				position: fixed;
				top: 58px;
				overflow: scroll;
				max-height: 90%;
			}
			&.checkout-main-cart-section-timer-on {
				top: 82px;
			}
		}

		#checkout-step {
			padding-right: 0 !important;
			&.checkout-step-payment {
				.checkout-payment-switch-payment {
					&.show {
						margin-bottom: 50px;
					}
				}
			}

			&.checkout-step-complete {
				.checkout-step-complete-section-title {
					margin: 0 -20px;
				}
				#checkout-step-complete-thankyou {
					#checkout-step-complete-thankyou-title {
						font-size: 20px;
					}
					.checkout-step-complete-thankyou-text {
						margin-top: 5px;
						font-size: 14px;
					}
				}
				#checkout-step-complete-summary-pickandpay-code {
					padding: 20px 0;
					margin-top: 15px;
					span {
						padding: 5px 0;
						width: 9%;
					}
				}
				&#checkout-step {
					padding-right: 0 !important;
				}
			}
			&.checkout-step-paymentcc {
				.widi-form {
					padding: 30px 0;
				}
				.displayed-cart {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 30px;
					max-width: initial;
					padding: 8px 16px;

					&-price {
						font-size: 16px;
					}
				}
			}
			.titleAndIcon {
				.title {
					margin-bottom: 20px;
				}
			}
			.dateCvvBlock {
				margin-top: 40px;
			}
		}
		.checkout-step-navigation {
			flex-direction: column-reverse;
			.checkout-step-back {
				margin-top: 15px;
			}
		}
	}

	/*  #checkout-footer-help-mobile {
			}
		}
	}

	.checkout-login-cta {
		margin-top: -5px !important;
		margin-bottom: 25px !important;
		margin-left: 5% !important;
		padding: 0 !important;

		&.logout {
			position: absolute;
			top: 13px;
			right: 22px;
		}
	}

	.checkout-login-dialog-content {
		width: auto;
	}

	.checkout-login-dialog {
		.widi-form {
			margin: 0;

			.widi-submit {
				padding-top: 30px;
			}
		}
	}

	#checkout-main {
		margin: 0 !important;

		#checkout-main-data-section {
			order: 2;
			-webkit-order: 2;
			padding: 0 20px 20px 20px !important;
		}

		#checkout-main-cart-section {
			padding: 0;
			&.sticky {
				z-index: 999;
				background-color: #fff;
				width: 100%;
				position: fixed;
				top: 58px;
				overflow: scroll;
				max-height: 90%;
			}
			&.checkout-main-cart-section-timer-on {
				top: 82px;
			}
		}

		#checkout-step {
			padding-right: 0 !important;
			&.checkout-step-payment {
				.checkout-payment-switch-payment {
					&.show {
						margin-bottom: 50px;
					}
				}
			}

			&.checkout-step-complete {
				.checkout-step-complete-section-title {
					margin: 0 -20px;
				}
				#checkout-step-complete-thankyou {
					#checkout-step-complete-thankyou-title {
						font-size: 20px;
					}
					.checkout-step-complete-thankyou-text {
						margin-top: 5px;
						font-size: 14px;
					}
				}
				#checkout-step-complete-summary-pickandpay-code {
					padding: 20px 0;
					margin-top: 15px;
					span {
						padding: 5px 0;
						width: 9%;
					}
				}
				&#checkout-step {
					padding-right: 0 !important;
				}
			}
			&.checkout-step-paymentcc {
				.widi-form {
					padding: 30px 0;
				}
				.displayed-cart {
					display: flex;
					justify-content: space-between;
					align-items: center;
					margin-bottom: 30px;
					max-width: initial;
					padding: 8px 16px;

					&-price {
						font-size: 16px;
					}
				}
			}
			.titleAndIcon {
				.title {
					margin-bottom: 20px;
				}
			}
			.dateCvvBlock {
				margin-top: 40px;
			}
		}
		.checkout-step-navigation {
			flex-direction: column-reverse;
			.checkout-step-back {
				margin-top: 15px;
			}
		}
	}

	/*  #checkout-footer-help-mobile {
    display: flex !important;
    line-height: 20px;
    @include font-bold;

    span {
      margin: 0 13px;
    }

    a {
      @include font-bold;
    }
  }

  #checkout-footer-logo {
    margin: 1px auto;
  }

  #checkout-footer-info {
    min-height: 150px;
    text-align: justify;

    #checkout-footer-piva {
      border-top: 1px solid #fff;
      padding: 0 0 10px 0;
    }
  } */

	.widi-form {
		margin-top: 30px;
		padding-left: 0;

		.widi-form-component {
			max-width: 100% !important;
		}

		.widi-form-fieldset-content {
			max-width: 100% !important;
		}

		md-input-container {
			width: auto !important;
		}

		.widi-submit {
			margin-top: 15px;
		}
	}

	#checkout-identification {
		.widi-form {
			padding-right: 0;
		}

		.checkout-identification-helper {
			.checkout-identification-helper-text,
			.checkout-identification-helper-title {
				margin-left: 0;
			}
		}

		#checkout-identification-iccid-helper-image {
			display: none;

			&.mobile-visible {
				display: block;
			}
		}

		.checkout-identification-helper-iccid {
			display: none;

			&.mobile-visible {
				display: block;
			}
		}

		#checkout-identification-form {
			margin: 0 0 40px;
		}

		.checkout-identification-input-fields {
			width: 100%;
		}

		.checkout-identification-divider {
			display: none;
		}

		.checkout-tooltip-trigger {
			display: block !important;
		}

		#checkout-identification-label,
		#checkout-identification-subtitle {
			margin: 30px -30px 40px;
		}

		#checkout-identification-subtitle {
			margin-bottom: 0;
		}
	}

	&.checkout-step-complete {
		#checkout-step-complete-consolidation {
			&-image {
				background-image: url("checkout/images/banner_gigarete_mobile.jpg");
				height: 135px;
			}
		}

		.infographic {
			margin-top: 30px;
			background-image: url("checkout/images/info_videoriconoscimento_mobile.jpg") !important;
			height: 280px !important;
			&.infographicTied {
				// background-image: url("checkout/images/info_videoriconoscimento_mobile_tied.jpg") !important;
			}
		}
	}

	.checkout-tooltip {
		.checkout-tooltip-text {
			left: 0px;
		}

		&.mobileNumberTooltip {
			.checkout-tooltip-text {
				top: 478px;
			}
		}

		&.pecTooltip {
			.checkout-tooltip-text {
				top: 544px;
			}
		}
		&.generalConditionsTooltip,
		&.generalConditionsTooltipFDO {
			.checkout-tooltip-text {
				top: 295px;
				left: 20px;
				right: 20px;
			}
		}

		&.sameaddressTooltip,
		&.sameaddressTooltipOnlyDevice {
			.checkout-tooltip-text {
				left: auto;
				right: 0;
			}
		}
	}

	.checkout-step-contract-iccd-dialog-icon {
		margin-top: 10px;
	}

	.tooltipster-box {
		.icon {
			margin: 5px 0;
		}
	}

	#checkout {
		background-color: #fff;
		padding: 0;
		margin: 58px auto 60px;

		&.checkout-main-hidecart {
			margin: 0px auto 60px;
		}

		.checkout-hidecart {
			background: url('checkout/images/logo_small.svg') no-repeat center;
			box-shadow: 0 2px 15px 0px rgba(60, 60, 60, 0.2);
			width: 100%;
			height: 60px;
		}

		#checkout-progress {
			padding: 0 20px;
			height: 50px;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			margin: 10px 0 0 0;

			&.checkout-progress-timer-on {
				margin: 35px 0 0 0;
			}
		}
	}

	.widi-submit {
		width: 100%;

		.md-button {
			width: 100%;
		}
	}

	.widi-form {
		.widi-form-component {
			margin: 17px 0;
		}

		.widi-form-fieldset {
			.widi-form-fieldset-content {
				margin: 10px 0 20px;

				& > .layout-column,
				& > .layout-row {
					margin: 0;
				}
			}
		}
	}

	#checkout-header {
		z-index: 10;
		height: 60px;
		margin: 0;
		padding: 0;
		touch-action: none;

		#checkout-header-logo-bg {
			background-size: cover;
			background-repeat: no-repeat;
			width: 134px;
			height: 60px;
		}
	}

	#checkout-cart {
		padding: 1px;
		z-index: 999;
		border-radius: 0 0 6px 6px;
		.widi-form {
			margin: 0;
		}
	}

	#checkout-cart-toggle {
		&.sticky {
			position: fixed;
			z-index: 999;
			width: 100%;
			margin: 0;
			top: 0;
			padding: 15px 0px;
			background: $white;
			box-shadow: 0 2px 15px 0px rgba(60, 60, 60, 0.2);
			.checkout-cart-toggle-inner {
				margin: 0;
			}
		}
		&.checkout-cart-timer-on {
			padding: 15px 0px 0px 0px;
		}

		.checkout-cart-toggle-container {
			padding: 0 5px;

			.checkout-cart-toggle-inner {
				border-radius: 6pt;
				background: linear-gradient(to right, $primary-color, $primary-color--dark); // NEW $black
				color: $white;
				cursor: pointer;
				font-size: 1em;
				@include font-bold;
				padding: 5px 10px;
				text-transform: uppercase;
				justify-content: space-between;
				position: relative;
				&::after {
					content: "";
					display: block;
					position: absolute;
					top: 45%;
					right: 15px;
					width: 0;
					height: 0;
					border-style: solid;
					border-width: 5px 5px 0 5px;
					border-color: #fff transparent transparent transparent;
					transition: all 0.5 ease-in-out;
				}
				&.opened {
					&::after {
						border-width: 0 5px 5px 5px;
						border-color: transparent transparent #fff transparent;
					}
				}
				.total-price {
					padding-right: 20px;
				}
			}
		}

		#checkout-cart-logo-small {
			background: url('checkout/images/logo_small.svg') no-repeat center;
			background-size: contain;
		}
	}

	#checkout-cart-overlay {
		background-color: rgba(0, 0, 0, 0.4);
		bottom: 0;
		cursor: pointer;
		height: 100%;
		left: 0;
		right: 0;
		top: 0;
		width: 100%;
		z-index: 21;
		position: fixed;
	}

	#checkout-main {
		#checkout-step {
			&.checkout-step-contract {
				#checkout-step-contract-tos-details {
					margin-top: 0px;
				}
				.contract_download {
					display: block;
				}
			}
		}
	}

	/* #checkout-footer-info,
  .checkout-footer-help {
    padding: 0;
  } */

	.checkout-step-complete {
		#checkout-step-complete-instructions {
			.checkout-step-complete-summary-boxes {
				.checkout-step-complete-instructions-box {
					margin-bottom: 30px;
					.checkout-step-complete-instructions-box-background {
						margin-left: 10px;
					}
					.checkout-step-complete-instructions-box-text {
						max-width: 90%;
					}
				}
			}
		}
	}

	.checkout-step-fdoiccid {
		.widi-form-component {
			&-prefix {
				max-width: 30% !important;
			}
		}
		.iccid-block {
			.fixedLabel {
				top: 0;
			}
		}
	}
	md-dialog {
		.md-dialog-content {
			.iframe-title {
				margin-top: 30px;
			}
		}
	}
}

.checkout-identification-error-modal-close {
	background-image: url("checkout/images/cross.svg");
	position: absolute;
	height: 20px;
	width: 20px;
	background-repeat: no-repeat;
	right: 2px;
	top: 9px;
	cursor: pointer;
}

.otp-dialog {
	max-width: 320px;
	display: block;

	.otp-close {
		background-image: url("checkout/images/cross.svg");
		position: absolute;
		height: 20px;
		width: 20px;
		background-repeat: no-repeat;
		right: 10px;
		top: 15px;
		cursor: pointer;
	}

	#otp-countdown {
		position: absolute;
		bottom: 35px;
		right: 25px;
		height: 25px;
		width: 25px;
	}

	.widi-submit {
		margin-top: 30px;
	}

	.otp-title {
		color: $primary-color;
	}

	p {
		&.otp-link {
			color: $primary-color; // NEW $secundary-color
			cursor: pointer;
			text-decoration: underline;
		}

		&.invalidOtp {
			color: $alert--red; // NEW $alert--red
			margin: 10px auto;
			width: 125px;
		}
	}

	#otpInputContainer {
		width: 135px;
		margin: 0 auto;
		text-align: center;

		.md-input {
			font-size: 1.7em;
			text-align: center;
			margin: 0;
			padding: 0;
		}

		md-input-container {
			label {
				font-size: 1.7em;
				overflow: visible;
				padding: 0;
				margin: 0;
			}

			&.md-focused,
			&.md-input-has-value {
				label {
					display: none;
				}
			}
		}

		&.error {
			.widi-form-component {
				margin: 0;
			}

			md-input-container {
				border: none;
				margin: 0;
			}
		}
	}
}

@media (min-width: $layout-breakpoint-md) {
	#checkout {
		//max-width: 1024px;
		// background-color: $checkout-color-white;

		#checkout-main {
			// background-color: $checkout-color-white;
		}
	}
}

@media (min-width: $layout-breakpoint-xs) {
	#checkout-main-cart-section {
		order: 1;
	}
}

@media (max-width: 1036px) and (min-width: $layout-breakpoint-xs) {
	.checkout-step-shipping {
		.checkout-step-shipping-option {
			height: 220px;
			.checkout-step-shipping-selection-box-info {
				flex-direction: column;
				text-align: center;
				.checkout-step-shipping-selection-box-text {
					margin-top: 5px;
				}
			}
			&:not(.disabled) {
				md-radio-button {
					height: 99%;
				}
			}
		}
	}
}

//$checkbox-width: 20px !default;
//$checkbox-height: $checkbox-width !default;
//$checkbox-border-radius: 2px !default;
//$checkbox-border-width: 2px !default;
//
// ^^ defined in variables.scss
//
$checkbox-margin: 16px !default;
$checkbox-text-margin: 10px !default;
$checkbox-top: 12px !default;

.md-inline-form {
  md-checkbox {
    margin: 19px 0 18px;
  }
}

md-checkbox {
  box-sizing: border-box;
  display: inline-block;
  margin-bottom: $checkbox-margin;
  white-space: nowrap;
  cursor: pointer;
  outline: none;
  user-select: none;
  position: relative;
  min-width: $checkbox-width;
  min-height: $checkbox-width;
  @include rtl(margin-left, 0, $checkbox-margin);
  @include rtl(margin-right, $checkbox-margin, 0);

  &:last-of-type {
    margin-left: 0;
    margin-right: 0;
  }

  &.md-focused:not([disabled]) {
    .md-container:before {
      left: -8px;
      top: -8px;
      right: -8px;
      bottom: -8px;
    }

    &:not(.md-checked) {
      .md-container:before {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
  }

  &.md-align-top-left > div.md-container {
    top: $checkbox-top;
  }

  @include checkbox-container;

  .md-label {
    box-sizing: border-box;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
    user-select: text;

    @include rtl(margin-left, $checkbox-text-margin + $checkbox-width, 0);
    @include rtl(margin-right, 0, $checkbox-text-margin + $checkbox-width);

  }
}

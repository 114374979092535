.md-panel-outer-wrapper {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

._md-panel-hidden {
  display: none;
}

._md-panel-offscreen {
  left: -9999px;
}

._md-panel-fullscreen {
  border-radius: 0;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  position: fixed;
  top: 0;
}

// Only used when no animations are present.
._md-panel-shown .md-panel {
  opacity: 1;
  transition: none;
}

.md-panel {
  opacity: 0;
  position: fixed;

  &._md-panel-shown {
    // Only used when custom animations are present.
    // Overridden by the default animations.
    opacity: 1;
    transition: none;
  }

  &._md-panel-animate-enter {
    opacity: 1;
    transition: $material-enter;
  }

  &._md-panel-animate-leave {
    opacity: 1;
    transition: $material-leave;
  }

  &._md-panel-animate-scale-out,
  &._md-panel-animate-fade-out {
    opacity: 0;
  }

  &._md-panel-backdrop {
    height: 100%;
    position: absolute;
    width: 100%;
  }

  &._md-opaque-enter {
    opacity: .48;
    transition: opacity $material-enter-duration $material-enter-timing-function;
  }

  &._md-opaque-leave {
    transition: opacity $material-leave-duration $material-leave-timing-function;
  }
}

md-toast {

  .md-toast-content {
    background-color: #323232;

          color: paper-color($background, "50");
          color: RGB(var(--background-50));
        

    .md-button {

          color: paper-color($background, "50");
          color: RGB(var(--background-50));
        

      &.md-highlight {
        // By default the toast should use the accent color as the primary highlight color

          color: paper-color($accent, "500");
          color: RGB(var(--accent-500));
        

        &.md-primary {

          color: paper-color($primary, "500");
          color: RGB(var(--primary-500));
        
        }

        &.md-warn {

          color: paper-color($warn, "500");
          color: RGB(var(--warn-500));
        
        }
      }
    }
  }
}

#checkout-footer {
  width: 100%;
  left: 0;
  color: $secondary-color;
  font-size: 12px;
  font-weight: 500;
  box-shadow: 0 -2px 15px 0px rgba(0, 0, 0, 0.5);

  /* #checkout-footer-logo {
    min-height: 100px;
    @include svg-background('checkout/images/logo_white.svg', 100px);
  } */

}

.checkout-footer-help {
  background-color: $white; // NEW $grey--light
  height: 54px;
}

.checkout-footer-help-container {
  margin: 0 auto;
  max-width: 1024px;
  padding: 0 30px;
  #checkout-footer-legal {
    div {
      display: inline;
    }
  }
}

#checkout-footer-info {
  background: linear-gradient(to right, $primary-color, $primary-color--dark);
  color: $white;
  padding: 24px 0;
  text-align: center;
  margin-top: 0;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-line-pack: center;
  align-content: center;
  /* .checkout-footer-help-container {
    border-top: 1px solid $black;
  } */
  #checkout-footer-piva {
   // border-color: $black;
  }
}

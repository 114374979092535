.checkout-step-customer-identification {
  padding-right: 0 !important;
  margin-top: 50px;

  .widi-form {
    padding-left: 0;
  }

  #otp-countdown {
    height: 24px;
    width: 24px;
    margin-top: 10px;
  }

  .newOtp-link {
    margin-top: 20px;
    color: $secondary-color;
    cursor: pointer;
    text-decoration: underline;
    @include font-bold;
  }
  #checkout-step-customer-identification {
    max-width: 540px;

    .checkout-step-customer-identification-header {
      text-align: center;

      .checkout-step-customer-identification-header-title {
        font-size: 1.16em;
        @include font-bold;
        margin-bottom: 30px;
        text-transform: none;
      }

      .checkout-step-customer-identification-header-info {
        font-size: 16px;
        margin-bottom: 40px;
        line-height: 1.4;
      }
    }

    .checkout-step-customer-identification-otpsent {
      font-size: 16px;
      line-height: 1.4;
      margin: 20px auto;
      text-align: center;
    }

    .widi-form {
      margin: 0 auto;
      .widi-form-component {
        margin-bottom: 30px;
      }
    }

    .checkout-step-customer-identification-phone-number-form,
    .checkout-step-customer-identification-otp-form {
      max-width: 240px;
      margin: 0 auto 85px;
    }
  }
}

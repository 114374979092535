#checkout-identification {
  
  margin: 20px 0;
  padding: 0 30px;

  .widi-form {
    padding-left: 0;
  }

  #checkout-identification-confirm {
    text-transform: uppercase;
  }

  #checkout-identification-title {
    font-size: 1.3em;
  }

  #checkout-identification-subtitle {
    background: $primary-color; // NEW $primary-color
    color: $white; // NEW $white
    font-size: 1.2em;
    @include font-bold;
    padding: 5px 10px;
    margin: 40px 0;
    text-transform: uppercase;
  }

  .widi-submit {
    margin-top: 25px;
  }

  .widi-form-fieldset {
    margin: 10px 0 30px;
  }

  .md-char-counter {
    position: absolute;
    top: 35px;
    right: 0;
    display: block;
  }

  .checkout-identification-checkmark {
    background-image: url("checkout/images/icona_check.svg");
    height: 20px;
    width: 20px;
    display: inline-block;
    position: absolute;
    top: -2px;
    background-repeat: no-repeat;
    background-size: contain;
  }

  #checkout-identification-label {
    background: $primary-color; // NEW $secundary-color
    color: $white; // NEW $white
    font-size: 1.2em;
    @include font-bold;
    padding: 5px 10px;
    margin: 40px 0;
    text-transform: uppercase;
  }

  .checkout-tooltip-trigger {
    display: none;
  }

  .checkout-identification-helper {
    width: 100%;

    .checkout-identification-helper-container {
      position: relative;
      margin-top: 25px;
    }

    .checkout-identification-helper-title {
      font-size: 1.15em;
      @include font-bold;
      margin-left: 30px;
    }

    .checkout-identification-helper-text {
      font-size: 1.1em;
      margin-left: 30px;
      margin-top: 5px;
    }
  }

  .checkout-identification-helper-iccid {
    margin-bottom: 10px;

    .checkout-identification-helper-title {
      margin-left: 0;
    }

    .checkout-identification-helper-text {
      margin-left: 0;
    }
  }

  #checkout-identification-iccid-helper-image {
    margin: 10px 0;
    width: 220px;
    height: 138px;
    background-image: url("checkout/images/img_sim_iccid_large.jpg");
    background-repeat: no-repeat;
    background-size: contain;
  }

  .checkout-identification-divider {
    height: 15vh;
    border-left: 2px solid $grey--light; // NEW $grey
  }
}

.checkout-step-paymentcc {
	.widi-form {
		max-width: 770px;
		padding: 80px 0;
		margin: 0 auto;
		.widi-form-fieldset-title {
			margin-bottom: 20px;
		}
		.displayed-cart {
			margin-bottom: 45px;
			border: 1px solid $grey--light;
			background: $white;
			padding: 10px 24px;
			margin-bottom: 30px;
			border-radius: 6px;
			max-width: 140px;
			box-sizing: border-box;
			&-title {
				font-size: 16px;
				color: #49545C;
			}
			&-price {
				@include font-bold;
				color: $primary-color;
				font-size: 24px;
			}
		}
		.titleAndIcon {
			align-items: center;
			margin-bottom: 40px;
			.widi-form-fieldset-title {
				margin: 0;
			}
			.card-icons {
				display: flex;
				justify-content: space-between;
				.icon {
					width: 40px;
					height: 28px;
					margin-right: 10px;
					background-size: cover;
					&.visa {
						background: url("checkout/images/payment_icons/visa.svg") no-repeat center;
					}
					&.mastercard {
						background: url("checkout/images/payment_icons/mastercard.svg") no-repeat center;
					}
					&.amex {
						background: url("checkout/images/payment_icons/americanexpress.svg") no-repeat center;
					}
					&.diners {
						background: url("checkout/images/payment_icons/dinersclub.svg") no-repeat center;
					}
					&:last-child {
						margin: 0;
					}
				}
			}
		}
		.dateCvvBlock {
			position: relative;
			.fixedLabel {
				position: absolute;
				top: -15px;
				font-size: 13px;
				color: $grey;
			}
		}
		.widi-input {
			&.widi-form-component-expireMonth {
				md-input-container {
					&.md-input-focused,
					&.md-input-invalid,
					&.md-input-has-value {
						label {
							display: none;
						}
					}
				}
			}
			&.widi-form-component-expireYear {
				md-input-container {
					&.md-input-focused,
					&.md-input-invalid,
					&.md-input-has-value {
						label {
							display: none;
						}
					}
				}
			}
			.paper-input-error {
				margin-left: 0;
			}
		}
	}
}
.checkout-dialog {
	&.cvv-modal {
		max-width: 448px;
		.checkout-dialog-info {
			width: 48px;
			height: 48px;
			background: url("checkout/images/ic_info_orange.svg") no-repeat center;
			background-size: contain;
		}
		.checkout-dialog-title {
			font-size: 20px;
		}
		.checkout-dialog-text {
			font-size: 16px;
			margin-bottom: 30px;
		}
		.checkout-dialog-icon {
			background-position: center;
			background-size: contain;

			&.checkout-step-payment-dialog-icon-cc {
				background: url("checkout/images/cvv.png") no-repeat center;
				width: 236px;
				height: 86px;
				margin: 0 auto 30px;
			}
		}
		.md-button {
			width: 100%;
			height: 50px;
			background: linear-gradient(90deg, #ff6900 0%, #fc4a14 100%);
			color: #ffffff;
			border-radius: 40px;
			font-size: 14px;
			font-family: "Montserrat", sans-serif;
			font-weight: 700;
		}
	}
}

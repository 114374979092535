$radio-width: 20px !default;
$radio-height: $radio-width !default;
$radio-text-margin: 10px !default;
$radio-top-left: 12px !default;
$radio-margin: 16px !default;

@mixin md-radio-button-disabled {
  cursor: default;

  .md-container {
    cursor: default;
  }
}

md-radio-button {
  box-sizing: border-box;
  display: block;
  margin-bottom: $radio-margin;
  white-space: nowrap;
  cursor: pointer;
  position: relative;

  // When the radio-button is disabled.
  &[disabled] {
    @include md-radio-button-disabled();
  }

  .md-container {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
    display: inline-block;
    width: $radio-width;
    height: $radio-width;
    cursor: pointer;
    @include rtl(left, 0, auto);
    @include rtl(right, auto, 0);

    .md-ripple-container {
      position: absolute;
      display: block;
      width: auto;
      height: auto;
      left: -15px;
      top: -15px;
      right: -15px;
      bottom: -15px;
    }

    &:before {
      box-sizing: border-box;
      background-color: transparent;
      border-radius: 50%;
      content: '';
      position: absolute;
      display: block;
      height: auto;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      transition: all 0.5s;
      width: auto;
    }
  }

  &.md-align-top-left > div.md-container {
     top: $radio-top-left;
   }

  .md-off {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: $radio-width;
    height: $radio-width;
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;
    transition: border-color ease 0.28s;
  }

  .md-on {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    left: 0;
    width: $radio-width;
    height: $radio-width;
    border-radius: 50%;
    transition: transform ease 0.28s;
    transform: scale(0);
  }

  &.md-checked .md-on {
    transform: scale(0.50);
  }

  .md-label {
    box-sizing: border-box;
    position: relative;
    display: inline-block;

    @include rtl(margin-left, $radio-text-margin + $radio-width, 0);
    @include rtl(margin-right, 0, $radio-text-margin + $radio-width);

    vertical-align: middle;
    white-space: normal;
    pointer-events: none;
    width: auto;
  }
}

md-radio-group {
  /** Layout adjustments for the radio group. */
  &.layout-column,
  &.layout-xs-column, &.layout-gt-xs-column,
  &.layout-sm-column, &.layout-gt-sm-column,
  &.layout-md-column, &.layout-gt-md-column,
  &.layout-lg-column, &.layout-gt-lg-column,
  &.layout-xl-column {
    md-radio-button {
      margin-bottom: $radio-margin;
    }
  }

  &.layout-row,
  &.layout-xs-row, &.layout-gt-xs-row,
  &.layout-sm-row, &.layout-gt-sm-row,
  &.layout-md-row, &.layout-gt-md-row,
  &.layout-lg-row, &.layout-gt-lg-row,
  &.layout-xl-row {
    md-radio-button {
      margin-top: 0;
      margin-bottom: 0;
      @include rtl(margin-left, 0, $radio-margin);
      @include rtl(margin-right, $radio-margin, 0);

      &:last-of-type {
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  &:focus {
    outline: none;
  }

  &.md-focused {
    .md-checked .md-container:before {
      left: -8px;
      top: -8px;
      right: -8px;
      bottom: -8px;
    }
  }

  &[disabled] md-radio-button {
    @include md-radio-button-disabled();
  }
}

.md-inline-form {
  md-radio-group {
    margin: 18px 0 19px;
    md-radio-button {
      display: inline-block;
      height: 30px;
      padding: 2px;
      box-sizing: border-box;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}

@media screen and (-ms-high-contrast: active) {
  md-radio-button.md-default-theme .md-on {
    background-color: #fff;
  }
}

md-icon {

          color: paper-color($foreground, "2");
          color: var(--foreground-2);
        

  &.md-primary {

          color: paper-color($primary, "500");
          color: RGB(var(--primary-500));
        
  }

  &.md-accent {

          color: paper-color($accent, "500");
          color: RGB(var(--accent-500));
        
  }

  &.md-warn {

          color: paper-color($warn, "500");
          color: RGB(var(--warn-500));
        
  }
}


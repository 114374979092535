// !!Important - Theme-based Background-color can be configured in backdrop-theme.scss
//             - Animate background-color opacity only for `.md-opaque` styles

md-backdrop {
  transition: opacity 450ms;

  position: absolute;
  top:0;
  bottom:0;
  left: 0;
  right: 0;

  z-index: $z-index-backdrop;

  &.md-menu-backdrop {
    position: fixed !important;
    z-index: $z-index-menu - 1;
  }
  &.md-select-backdrop {
    z-index: $z-index-dialog + 1;
    transition-duration: 0;
  }
  &.md-dialog-backdrop {
    z-index: $z-index-dialog - 1;
  }
  &.md-bottom-sheet-backdrop {
    z-index: $z-index-bottom-sheet - 1;
  }
  &.md-sidenav-backdrop {
    z-index: $z-index-sidenav - 1;
  }


  &.md-click-catcher {
    position: absolute;
  }

  &.md-opaque {

    opacity: .48;

    &.ng-enter {
      opacity: 0;
    }
    &.ng-enter.md-opaque.ng-enter-active {
      opacity: .48;
    }
    &.ng-leave {
      opacity: .48;
      transition: opacity 400ms;
    }
    &.ng-leave.md-opaque.ng-leave-active {
      opacity: 0;
    }
  }

}


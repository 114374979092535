md-divider {
  display: block;
  border-top-width: 1px;
  border-top-style: solid;
  margin: 0;

  &[md-inset] {
    @include rtl-prop(margin-left, margin-right, $baseline-grid * 10, auto);
  }
}

.layout-row,
.layout-xs-row, .layout-gt-xs-row,
.layout-sm-row, .layout-gt-sm-row,
.layout-md-row, .layout-gt-md-row,
.layout-lg-row, .layout-gt-lg-row,
.layout-xl-row {
  & > md-divider {
    border-top-width: 0;
    border-right-width: 1px;
    border-right-style: solid;
  }
}

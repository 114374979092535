$progress-linear-bar-height: 5px !default;

md-progress-linear {
  display: block;
  position: relative;
  width: 100%;
  height: $progress-linear-bar-height;

  padding-top: 0 !important;
  margin-bottom: 0 !important;

  &._md-progress-linear-disabled {
    visibility: hidden;
  }

  .md-container {
    display:block;
    position: relative;
    overflow: hidden;

    width:100%;
    height: $progress-linear-bar-height;

    transform: translate(0, 0) scale(1, 1);

    .md-bar {
      position: absolute;

      left: 0;
      top: 0;
      bottom: 0;

      width: 100%;
      height: $progress-linear-bar-height;
    }

    .md-dashed:before {
      content: "";
      display: none;
      position: absolute;

      margin-top: 0;
      height: $progress-linear-bar-height;
      width: 100%;

      background-color: transparent;
      background-size: 10px 10px !important;
      background-position: 0px -23px;
    }

    .md-bar1, .md-bar2 {

      // Just set the transition information here.
      // Note: the actual transform values are calculated in JS

      transition: transform 0.2s linear;
    }

    // ************************************************************
    // Animations for modes: Determinate, InDeterminate, and Query
    // ************************************************************

    &.md-mode-query {
        .md-bar1 {
          display: none;
        }
        .md-bar2 {
          transition: all 0.2s linear;
          animation: query .8s infinite cubic-bezier(0.390, 0.575, 0.565, 1.000);
        }
      }

    &.md-mode-determinate {
      .md-bar1 {
        display: none;
      }
    }

    &.md-mode-indeterminate {
      .md-bar1 {
        animation: md-progress-linear-indeterminate-scale-1 4s infinite,
                   md-progress-linear-indeterminate-1 4s infinite;
      }
      .md-bar2 {
        animation: md-progress-linear-indeterminate-scale-2 4s infinite,
                   md-progress-linear-indeterminate-2 4s infinite;
      }
    }

    &.ng-hide
    ._md-progress-linear-disabled & {
      animation: none;

      .md-bar1 {
        animation-name: none;
      }
      .md-bar2 {
        animation-name: none;
      }
    }
  }

  // Special animations for the `buffer` mode

  .md-container.md-mode-buffer {
    background-color: transparent !important;

    transition: all 0.2s linear;

    .md-dashed:before {
      display: block;
      animation: buffer 3s infinite linear;
    }
  }
}

@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(35%) scale(.3, 1);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) scale(0, 1);
  }
}
@keyframes buffer {
  0% {
    opacity: 1;
    background-position: 0px -23px;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
    background-position: -200px -23px;
  }
}
@keyframes md-progress-linear-indeterminate-scale-1 {
  0% {
    transform: scaleX(0.1);
    animation-timing-function: linear;
  }
  36.6% {
    transform: scaleX(0.1);
    animation-timing-function: cubic-bezier(0.334731432, 0.124819821, 0.785843996, 1);
  }
  69.15% {
    transform: scaleX(0.83);
    animation-timing-function: cubic-bezier(0.225732004, 0, 0.233648906, 1.3709798);
  }
  100% {
    transform: scaleX(0.1);
  }
}
@keyframes md-progress-linear-indeterminate-1 {
  0% {
    left: -378.6 * 100% / 360;
    animation-timing-function: linear;
  }
  20% {
    left: -378.6 * 100% / 360;
    animation-timing-function: cubic-bezier(0.5, 0, 0.701732, 0.495818703);
  }
  69.15% {
    left: 77.4 * 100% / 360;
    animation-timing-function: cubic-bezier(0.302435, 0.38135197, 0.55, 0.956352125);
  }
  100% {
    left: 343.6 * 100% / 360;
  }
}
@keyframes md-progress-linear-indeterminate-scale-2 {
  0% {
    transform: scaleX(0.1);
    animation-timing-function: cubic-bezier(0.205028172, 0.057050836, 0.57660995, 0.453970841);
  }
  19.15% {
    transform: scaleX(0.57);
    animation-timing-function: cubic-bezier(0.152312994, 0.196431957, 0.648373778, 1.00431535);
  }
  44.15% {
    transform: scaleX(0.91);
    animation-timing-function: cubic-bezier(0.25775882, -0.003163357, 0.211761916, 1.38178961);
  }
  100% {
    transform: scaleX(0.1);
  }
}
@keyframes md-progress-linear-indeterminate-2 {
  0% {
    left: -197.6 * 100% / 360;
    animation-timing-function: cubic-bezier(0.15, 0, 0.5150584, 0.409684966);
  }
  25% {
    left: -62.1 * 100% / 360;
    animation-timing-function: cubic-bezier(0.3103299, 0.284057684, 0.8, 0.733718979);
  }
  48.35% {
    left: 106.2 * 100% / 360;
    animation-timing-function: cubic-bezier(0.4, 0.627034903, 0.6, 0.902025796);
  }
  100% {
    left: 422.6 * 100% / 360;
  }
}



#checkout-cart {
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
	border-radius: 6px;
	.blue-link {
		font-size: 1.4em;
	}

	#checkout-cart-title {
		background-color: $white; // NEW $black
		color: $black; // NEW $white
		//border-radius: 6px 6px 0 0;
		height: 40px;
		line-height: 45px;
		font-size: 16px;
		@include font-bold;
		margin: 0 0 2px;
		padding: 0 20px;
		text-transform: uppercase;
	}

	.checkout-cart-section-title {
		background-color: $grey--medium; // NEW $grey--medium
		color: $white; // NEW $white
		line-height: 30px;
		@include font-bold;
		margin: 2px;
		padding: 5px 20px;
		height: 30px;
	}

	.checkout-cart-price {
		text-align: right;

		.checkout-cart-price-amount {
			@include font-bold;
		}

		.checkout-cart-price-text {
			color: $black; // NEW $black
			padding-left: 10px;
		}
	}

	#checkout-cart-content {
		.checkout-cart-item-detail {
			padding: 10px 15px;

			&.checkout-cart-price {
				background-color: $white;

				.checkout-cart-price-description {
					@include font-bold;
					text-align: left;
					font-size: 1em;
				}

				.checkout-cart-info-device {
					@include font-regular;
					text-transform: none;
					margin: 5px 0;
					text-align: left;
				}
			}

			.checkout-cart-price-original {
				@include font-bold;
				font-size: 0.85em;
				margin-right: 5px;
				text-decoration: line-through;
			}

			.checkout-cart-price-description {
				@include font-light;
				font-size: 0.86em;
			}
		}

		.checkout-cart-item-details,
		.checkout-cart-shipping-price {
			background-color: $grey--lightest;

			.checkout-cart-item-detail {
				border-bottom: 2px solid $white;

				&:last-child {
					border-bottom: none;
				}
			}
		}

		.checkout-cart-item {
			margin: 2px 0;

			&:nth-child(n + 2):last-child() {
				margin-top: 10px;
			}
		}

		.checkout-cart-item,
		.checkout-cart-item-detail {
			.checkout-cart-price-amount {
				@include font-bold;
				font-size: 1.15em;
				position: relative;
				.ppePrice {
					position: absolute;
					top: 0;
					height: 1px;
					width: 20px;
					right: 0;
					background: $black;
				}
			}
		}

		#checkout-cart-shipping {
			padding: 15px 20px;
			background-color: $grey--lightest;
			@include font-regular;

			#checkout-cart-shipping-price {
				.checkout-cart-shipping-price-description {
					@include font-bold;
					font-size: 0.85em;

					.checkout-cart-shipping-price-description-data {
						@include font-light;
					}
				}

				.checkout-cart-shipping-price-amount {
					@include font-bold;
				}
			}
		}

		#checkout-cart-coupon {
			font-size: 0.85em;
			margin: 15px;

			.widi-form {
				padding: 0;

				.widi-form-component {
					margin: 10px 0;

					md-input-container {
						width: auto;
					}
				}
			}

			#checkout-cart-coupon-code {
				position: relative;

				.checkout-tooltip-check {
					position: absolute;
					top: 15px;
					right: 0;
					@include svg-background("checkout/images/ic_check.svg", 20px);
				}
			}

			#checkout-cart-coupon-button {
				color: $primary-color;
				@supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
					background-image: -webkit-linear-gradient(0deg, $primary-color, $primary-color--dark 20%);
					-webkit-background-clip: text;
				}
				text-transform: uppercase;
				height: 34px;
				border: 1px solid $primary-color; // NEW $secundary-color
				border-radius: 50px;
				cursor: pointer;
				line-height: 34px;
				text-align: center;

				&:disabled {
					opacity: 0.3;
					cursor: not-allowed;
				}

				.spin-animate {
					position: absolute;
					top: 1px;
					left: 35%;
					background-color: transparent; // NEW $secundary-color
				}
			}
		}

		#checkout-cart-summary {
			margin: 2px;

			#checkout-cart-summary-total {
				background: linear-gradient(
					to right,
					$primary-color,
					$primary-color--dark
				); //$primary-color; // NEW $secundary-color
				color: $white; // NEW $white
				margin: 0;
				padding: 5px 20px;
				height: 60px;
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;

				#checkout-cart-summary-total-label {
					@include font-bold;
					text-transform: uppercase;
				}

				#checkout-cart-summary-total-amount {
					font-size: 1.3em;
				}
			}
		}

		#checkout-cart-campaign-banner {
			width: 100%;
		}
	}

	#checkout-cart-items {
		.checkout-cart-price-text {
			color: $black; // NEW $black
			font-size: 0.72em;
			text-align: right;
			max-width: 60%;
		}

		.checkout-cart-selected-option {
			padding: 15px;

			.checkout-cart-item-title {
				@include font-bold;
				text-transform: capitalize;
			}

			.checkout-cart-price-amount {
				font-size: 1.15em;
			}
		}

		#checkout-cart-options {
			@include font-regular;

			.checkout-cart-options-preview {
				> .ember-view {
					&:nth-last-child(1) {
						.checkout-cart-selected-option-divider {
							display: none;
						}
					}
				}
			}

			.checkout-cart-selected-option-divider {
				height: 1px;
				background: $grey--light; // NEW $grey
				margin: 0 15px;
			}

			.checkout-cart-option-remove {
				color: $secondary-color; // NEW $secundary-color
				text-decoration: underline;
				font-size: 0.85em;
				cursor: pointer;
			}

			.checkout-cart-options-control {
				border: solid 1px $secondary-color; // NEW $secundary-color
				margin: 2px 2px 0;

				.checkout-cart-options-header {
					cursor: pointer;
					padding: 10px 30px 10px 10px;

					.checkout-cart-options-title {
						@include font-bold;
					}
				}

				.checkout-cart-options-subtitle {
					font-size: 0.85em;

					&.checkout-cart-options-subtitle::first-letter {
						text-transform: capitalize;
					}
				}
			}

			.checkout-cart-options-content {
				border-top: solid 1px $secondary-color; // NEW $secundary-color
				margin: 0 10px;

				.checkout-cart-item-options-category {
					padding: 10px 0 0;

					.md-label {
						width: 90%;
					}

					.checkout-cart-item-options-category-title {
						@include font-bold;
						padding: 0 0 10px;

						.checkout-cart-item-options-category-reset {
							@include font-regular;
							text-transform: capitalize;
						}
					}

					.checkout-cart-item-options-category-option {
						padding: 0 5px;

						.checkout-cart-item-options-category-option-name {
							text-transform: capitalize;
							font-size: 0.85em;
						}

						.checkout-cart-item-options-category-option-price {
							@include font-bold;
							font-size: 1.15em;
							text-align: right;
							padding-right: 10px;
						}
					}
				}
			}
		}
	}
}

.checkout-step-fork {
  .checkout-step-form-discounted-sim-label {
    margin-left: 30px;
    background-color: $secondary-color; // NEW $secundary-color
    color: $white;
    padding: 8px;
    width: fit-content;
    @include font-bold;
    margin-top: -20px;
    margin-bottom: 20px;
  }

  md-radio-button {
    margin-bottom: 30px;
  }

  .widi-form {
    md-input-container {
      width: auto;
    }
  }

  .checkout-step-fork-stream-choice-title {
    @include font-bold;
  }

  #checkout-step-fork-residence-info {
    @include font-bold;
    margin-top: -20px;
    margin-bottom: 40px;
    font-size: 1em;
  }

  .checkout-step-fork-conditions {
    @include font-regular;
    margin-bottom: 10px;
    font-size: 12px;
  }

  .pickstore-flow {
    background: url("checkout/images/store-icon.svg") no-repeat;
    padding: 0 0 35px 55px;
  }
}

.checkout-step-simfork{
    .widi-form-fieldset {
        min-height: 400px;
    }
    &-choice {
		padding: 20px;
		box-shadow: 10px 11px 33px 0 rgba(0, 0, 0, 0.1);
		background: white;
		border-radius: 6px;
		margin-bottom: 30px;
        border: 1px solid transparent;
		md-radio-button {
			margin-bottom: 0;
			display: flex;
			flex-direction: row-reverse;
			align-items: center;
			justify-content: space-between;
			.md-container {
				position: relative;
				top: initial !important;
				transform: unset;
			}
			.md-label {
				margin-left: 0;
                width: 100%;
                max-width: 90%;
			}
		}
		&-content {
            display: flex;
			align-items: center;
        }
        &-icon {
            margin-right: 16px;
        }
		&.active {
			border-color: $secondary-color;
		}
    }
}
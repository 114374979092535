$progress-circular-indeterminate-duration: 1568.63ms !default;

@keyframes indeterminate-rotate {
    0%       { transform: rotate(0deg); }
    100%     { transform: rotate(360deg); }
}

// Used to avoid unnecessary layout
md-progress-circular {
    position: relative;
    display: block;

    &._md-progress-circular-disabled {
        visibility: hidden;
    }

    &.md-mode-indeterminate svg {
        animation: indeterminate-rotate $progress-circular-indeterminate-duration linear infinite;
    }

    svg {
        position: absolute;
        overflow: visible;
        top: 0;
        left: 0;
    }
}
